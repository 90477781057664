/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { helpIcon } from '../Icons'

export class Checkbox extends Component {
  static defaultProps = {
    label: null,
    name: null,
    value: null,
    className: null,
    onChange: null,
    checked: false,
    disabled: false,
    required: false
  };

  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    helptext: PropTypes.string,
    helptextclassname: PropTypes.string,
    required: PropTypes.bool
  };

  constructor () {
    super()
    this.onClick = this.onClick.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  onClick (e) {
    if (this.props.disabled) {
      return
    }
    if (this.props.onChange) {
      this.props.onChange({
        originalEvent: e,
        name: this.props.name,
        value: this.props.value,
        checked: !this.props.checked
      })
    }
  }

  onFocus () {
    if (this.props.helptext) {
      this.helpElement.style.display = 'block'
    }
  }

  onBlur () {
    if (this.props.helptext) {
      this.helpElement.style.display = 'none'
    }
  }

  render () {
    let boxClass = classNames('ui-chkbox-box ui-widget ui-corner-all ui-state-default', { 'ui-state-active':this.props.checked },
      { 'ui-state-disabled': this.props.disabled })
    let iconClass = classNames('ui-chkbox-icon ui-c', { 'fa fa-check': this.props.checked })

    return (
      <div className='ui-chkbox-container d-inline-flex align-items-center' tabIndex={0} onFocus={this.onFocus} onBlur={this.onBlur}>
        <div className='ui-chkbox ui-widget'>
          <div className='ui-helper-hidden-accessible'>
            <input type='checkbox' name={this.props.name} disabled={this.props.disabled} />
          </div>
          <div className={boxClass} onClick={this.onClick} disabled={this.props.disabled}>
            <span className={iconClass} />
          </div>
          {this.props.helptext
            ? <div ref={(e) => { this.helpElement = e }} className={classNames('help-message', this.props.helptextclassname)}
              style={{ display: 'none' }}>{helpIcon}
              <div className='help-text' dangerouslySetInnerHTML={{ __html: this.props.helptext }} /></div>
            : null }
        </div>
        {this.props.label && <label className={classNames('ui-chkbox-label pl-0 mb-0', { 'ui-state-disabled': this.props.disabled }, this.props.className)}
          disabled={this.props.disabled}><span dangerouslySetInnerHTML={{ __html: this.props.label }} />
          {this.props.required ? <span className='redStar'>*</span> : null}</label>}
      </div>
    )
  }
}

export default Checkbox
