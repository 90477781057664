import { combineReducers } from 'redux'
import loginReducer from '../routes/Login/modules/login'
import { fetchReducer } from './fetchReducer'
import { connectRouter } from 'connected-react-router'

export const makeRootReducer = (asyncReducers, history) => {
  return combineReducers({
    router: connectRouter(history),
    login: loginReducer,
    fetch: fetchReducer,
    ...asyncReducers
  })
}

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
