/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DownloadButton from '../Common/DownloadButton'

export class Document extends Component {
  render () {
    const { mimeType, documentId, uploadTime, size, description, filename } = this.props
    return <div>
      { documentId ? <DownloadButton title={description || filename} mimeType={mimeType}
        documentId={`${documentId}`} fileDate={uploadTime} fileSize={size} /> : null }
    </div>
  }
}

Document.propTypes = {
  mimeType: PropTypes.string,
  documentId: PropTypes.number,
  uploadTime: PropTypes.string,
  size: PropTypes.number,
  description: PropTypes.string,
  filename: PropTypes.string
}

export default Document
