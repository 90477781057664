/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import { negativeAddressIcon, positiveAddressIcon, addressIcon, negativePasswordIcon, warningPasswordIcon,
  positiveEnvelopeIcon } from '../../../components/Common/Icons'

export const notificationIcon = (eventType) => {
  switch (eventType) {
    case 'passwordWillExpire': {
      return warningPasswordIcon
    }
    case 'passwordExpiredChangeNeeded': {
      return negativePasswordIcon
    }
    case 'emaiAndOrPhoneModificationOccured': {
      return positiveEnvelopeIcon
    }
    case 'personalDataModificationOccured': {
      return positiveAddressIcon
    }
    case 'personalDataModificationAccept': {
      return addressIcon
    }
    case 'personalDataModificationDeny': {
      return negativeAddressIcon
    }
  }
}
