/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropdown from '../../components/Common/UFPrimeReact/UFDropdown'
import { InputText } from '../../components/Common/UFPrimeReact/UFInputText'
import { iHelp } from '../../utils/commonFunctions'

export default class Phone extends Component {
  constructor (props) {
    super(props)

    this.setValue = this.setValue.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  static propTypes = {
    name: PropTypes.string,
    countryCode: PropTypes.string,
    phoneNumber: PropTypes.string,
    masterData: PropTypes.object,
    updatePhone: PropTypes.func.isRequired,
    helpkey: PropTypes.string,
    helptextclassname: PropTypes.string
  }

  setValue (ev) {
    this.props.updatePhone(this.props.name, ev.target.name, ev.target.value)
  }

  onChange (ev) {
    this.props.updatePhone(this.props.name, 'countryCode', ev.value)
  }

  phoneTemplate (option, countries) {
    if (!option.value) {
      return option.label
    } else {
      const country = countries.filter((e) => e.value === option.value)
      return (
        <div className='ui-helper-clearfix' style={{ width: '180px' }}>
          <span>{`${option.label}` + ' (' + `${country[0] ? country[0].label : ''}` + ')'}</span>
        </div>
      )
    }
  }

  render () {
    const { Country } = this.props.masterData
    let countriesOptions = []
    let countries = []
    Object.entries(Country).forEach(([key, value]) => {
      countriesOptions.push({
        label: '+' + value[0].content.countryCode,
        value: '+' + value[0].content.countryCode })
      countries.push({
        label: value[0].content.countryName,
        value: '+' + value[0].content.countryCode })
    })

    return <div className='form-row'>
      <div className='col-md-4 col-sm-3'>
        <Dropdown options={countriesOptions}
          name='countryCode'
          value={this.props.countryCode}
          editable
          itemTemplate={(option) => this.phoneTemplate(option, countries)}
          onChange={this.onChange}
          autoWidth={false}
          style={{ minWidth: '60px' }} />
      </div>
      <div className='col-md-8 col-sm-9'>
        <InputText className='form-control' placeholder='Telefonszám'
          name='phoneNumber'
          value={this.props.phoneNumber}
          onChange={this.setValue}
          helptext={this.props.helpkey ? iHelp(this.props.masterData, this.props.helpkey) : ''}
          helptextclassname={this.props.helptextclassname}
        />
      </div>
    </div>
  }
}
