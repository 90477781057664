/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import { CALL_API } from './api.js'
import { UPDATE_SUCCESS } from './update'

export const FETCH_REQUEST = 'FETCH_REQUEST'
export const FETCH_SUCCESS = 'FETCH_SUCCESS'
export const FETCH_FAILURE = 'FETCH_FAILURE'
export const FETCH_CLEAR = 'FETCH_CLEAR'

export const actionTypes = [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE]

/**
 * Létrehoz egy dispatchelhető fetch actiont az alábbi paraméterekkel
 * @param statePath a Redux store-ban ezalatt a path (property) alatt lesznek a betöltött adatok (a combineReducer-nek
 * a key paraméterét helyettesíti lényegében)
 * @param subPath a statePath alatti property, ennek a nevében lehetnek egyedre jellemző adatok (pl. id)
 * @param queryParam a fetch endpointnak elküldött GET-es paraméter lista (?-jellel kezdődik és & választja
 * el a paramétereket, a paraméter neve és értéke között pedig = jel van
 * @param endPoint a backend-ben ezt az endpoint-ot kell hívni
 * @param convert a fetch-elt adatokat a convert által átalakított objektum szerkezetben menti a store-ba
 * @param clearCached ha true, akkor a FETCH_REQUEST a Redux store-ban már tárolt előző értéket törli, egyébként megmarad a korábbi
 * @returns {{}} az elkészült API hívásra alkalmas action
 */
export const createFetchAction = ({
  statePath, subPath, queryParam = '', endPoint, convert, clearCached
} = {}) => {
  return ({
    [CALL_API]: {
      types: actionTypes,
      statePath,
      subPath,
      convert,
      // eslint-disable-next-line no-undef
      endpoint: `${API_URL_JS}${endPoint.startsWith('/') ? '' : '/'}${endPoint}${queryParam}`,
      clearCached
    }
  })
}

export const clearFetchAction = {
  type: FETCH_CLEAR
}

// ---------------------------------------------------------
// Action Handlers
// ---------------------------------------------------------
const ACTION_HANDLERS = {
  [FETCH_REQUEST]: (convert, statePath, subPath, state, action) => {
    if (state[statePath] && state[statePath][subPath] && state[statePath][subPath].fetched && action.clearCached !== true) {
      return ({
        ...state,
        [statePath]: {
          ...state[statePath],
          [subPath]: {
            ...state[statePath][subPath],
            isRefreshing: true,
            isRefetchNeeded: false
          }
        }
      })
    } else {
      return ({
        ...state,
        [statePath]: {
          ...state[statePath],
          [subPath]: {
            isLoading: true,
            isLoaded: false
          }
        }
      })
    }
  },
  [FETCH_SUCCESS]: (convert, statePath, subPath, state, action) =>
    ({
      ...state,
      [statePath]: {
        ...state[statePath],
        [subPath]: {
          fetched: convert(action.response),
          isLoading: false,
          isLoaded: true,
          hasError: false
        }
      }
    }),
  [FETCH_FAILURE]: (convert, statePath, subPath, state, action) =>
    ({
      ...state,
      [statePath]: {
        ...state[statePath],
        [subPath]: {
          hasError: true,
          error: action.error
        }
      }
    })
}

export const fetchReducer = (state, action) => {
  if (action.type === UPDATE_SUCCESS && action.endpoint === 'update/sendBuyOffer') {
    return { ...state, buyOffersById: { } }
  } else if (action.type === UPDATE_SUCCESS &&
    (action.endpoint === 'update/sendBuyerDecision' || action.endpoint === 'update/sendOwnerDecision')) {
    return action.data && action.data.subProcessId && state.subProcessesById && state.subProcessesById[action.data.subProcessId]
      ? { ...state,
        subProcessesById: { ...state.subProcessesById,
          [action.data.subProcessId]: { ...state.subProcessesById[action.data.subProcessId], isRefetchNeeded: true } } } : state
  } else if (action.type === FETCH_CLEAR) {
    return { }
  } else if (action.type === 'LOCATION_CHANGE') {
    return { home: state.home }
  } else {
    const handler = ACTION_HANDLERS[action.type]

    if (handler) {
      const statePath = action.statePath
      const convert = action.convert || ((x) => x)
      const subPath = action.subPath
      return handler(convert, statePath, subPath, state, action)
    } else {
      return state || {}
    }
  }
}
