/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React from 'react'
import { formatDate } from '../../../utils/localization'
import { addressToString, phoneToString } from '../../../utils/commonFunctions'
import DocumentContainer from '../../../containers/DocumentContainer'
import Document from '../../../components/Document/Document'

const phones = (phones) => <div>
  <p className='form-control-static'>
    {phones ? phones.map((phone, index) => {
      return <span key={`phone_${index}`}>{phoneToString(phone)} {index < phones.length - 1 ? ' | ' : ''}</span>
    }) : null}
  </p>
</div>

const personalData = (item, individual) => <div className={individual ? 'row form-horizontal' : 'form-horizontal row ml-0'}>
  <div className='col-12 col-lg-6'>
    <div className='row'>
      <label className='col-form-label col-12 col-sm-4'>Állampolgárság:</label>
      <div className='col-12 col-sm-8'>
        <p className='form-control-plaintext'>{item.nationality}</p>
      </div>
    </div>
    {individual
      ? <div className='row'>
        <label className='col-form-label col-12 col-sm-4'>Titulus:</label>
        <div className='col-12 col-sm-8'>
          <p className='form-control-plaintext'>{item.compellation}</p>
        </div>
      </div>
      : null}
    <div className='row'>
      <label className='col-form-label col-12 col-sm-4'>Születési név:</label>
      <div className='col-12 col-sm-8'>
        <p className='form-control-plaintext'>{item.birthName}</p>
      </div>
    </div>
    <div className='row'>
      <label className='col-form-label col-12 col-sm-4'>Születési hely:</label>
      <div className='col-12 col-sm-8'>
        <p className='form-control-plaintext'>{item.birthPlace}</p>
      </div>
    </div>
    <div className='row'>
      <label className='col-form-label col-12 col-sm-4'>Születési idő:</label>
      <div className='col-12 col-sm-8'>
        <p className='form-control-plaintext'>{formatDate(item.birthDate)}</p>
      </div>
    </div>
  </div>
  <div className='col-12 col-lg-6'>
    <div className='row'>
      <label className={individual ? 'col-form-label col-12 col-sm-4' : 'col-form-label col-12 col-sm-4'}>Anyja neve:</label>
      <div className={individual ? 'col-12 col-sm-8' : 'col-12 col-sm-8'}>
        <p className='form-control-plaintext'>{item.motherName}</p>
      </div>
    </div>
    <div className='row'>
      <label className={individual ? 'col-form-label col-12 col-sm-4' : 'col-form-label col-12 col-sm-4'}>
        {individual ? 'Azonosító okmány típusa:' : 'Személyi azonosságot igazoló okmány típusa:'}</label>
      <div className={individual ? 'col-12 col-sm-8' : 'col-12 col-sm-8'}>
        <p className='form-control-plaintext'>{item.idCardType}</p>
      </div>
    </div>
    <div className='row'>
      <label className={individual ? 'col-form-label col-12 col-sm-4' : 'col-form-label col-12 col-sm-4'}>
        {individual ? 'Azonosító okmány száma:' : 'Személyi azonosságot igazoló okmány száma:'}</label>
      <div className={individual ? 'col-12 col-sm-8' : 'col-12 col-sm-8'}>
        <p className='form-control-plaintext'>{item.idCardNumber}</p>
      </div>
    </div>
  </div>
</div>

const addressData = (item) => <div className='col'>
  <div className='row'>
    <label className='col-form-label col-12 col-sm-4'>Lakcím:</label>
    <div className='col-12 col-sm-8'>
      <p className='form-control-plaintext'>{addressToString(item.address)}</p>
    </div>
  </div>
  <div className='row'>
    <label className='col-form-label col-12 col-sm-4'>Tartózkodási hely / Értesítési cím:</label>
    <div className='col-12 col-sm-8'>
      <p className='form-control-plaintext'>{addressToString(item.notificationAddress)}</p>
    </div>
  </div>
</div>

const licences = (customer) => <div>
  <h3 className='mt-4 mb-2'>Engedélyek és hozzáférések</h3>
  <div className='panel panel-default'>
    <div className='card-body'>
      <div className='form-horizontal'>
        <div className='row'>
          <label className='col-form-label col'>
            <b>Bankcsoporti Közös Adatkezelés:</b> Személyre szabott ajánlatok kidolgozása és ajánlattétel (profilalkotás):
          </label>
          <div className='col'>
            <p className='form-control-plaintext'>{customer.marketingEnabled ? 'Igen' : 'Nem'}</p>
          </div>
        </div>
        <div className='row'>
          <label className='col-form-label col'>
            <b>OTP Pénzügyi Pont Zrt.</b> részére történő adatátadás ajánlattétel céljából:
          </label>
          <div className='col'>
            <p className='form-control-plaintext'>{customer.financialEnabled ? 'Igen' : 'Nem'}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

const documents = (customer) => <div>
  <h3 className='mt-4 mb-2'>Elektronikus dokumentumok</h3>
  <div className='panel panel-default'>
    <div className='card-body'>
      <div className='form-horizontal row'>
        <div className='col'>
          { customer.identificationDocument ? <DocumentContainer component={Document} id={customer.identificationDocument} /> : null }
        </div>
      </div>
    </div>
  </div>
</div>

const companyProfile = (customer, ssoUserId) => <div className='row'>
  <div className='col-12'>
    <h3 className='mt-4 mb-2'>Alap kapcsolattartási adatok</h3>
    <div className='panel panel-default'>
      <div className='card-body'>
        <div className='form-horizontal row'>
          <div className='col-12 col-lg-6'>
            <div className='row'>
              <label className='col-form-label col-12 col-sm-4'>Név:</label>
              <div className='col-12 col-sm-8'>
                <p className='form-control-plaintext'>{customer.name}</p>
              </div>
            </div>
            <div className='row'>
              <label className='col-form-label col-12 col-sm-4'>Rövid cégnév:</label>
              <div className='col-12 col-sm-8'>
                <p className='form-control-plaintext'>{customer.shortenedCompanyName}</p>
              </div>
            </div>
            <div className='row'>
              <label className='col-form-label col-12 col-sm-4'>Vállalkozás formája:</label>
              <div className='col-12 col-sm-8'>
                <p className='form-control-plaintext'>{customer.companyType}</p>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-6'>
            <div className='row'>
              <label className='col-form-label col-12 col-sm-4'>Céges kapcsolattartó neve:</label>
              <div className='col-12 col-sm-8'>
                <p className='form-control-plaintext'>{customer.contact}</p>
              </div>
            </div>
            <div className='row'>
              <label className='col-form-label col-12 col-sm-4'>Céges kapcsolattartó e-mail címe:</label>
              <div className='col-12 col-sm-8'>
                <p className='form-control-plaintext'>{customer.contactEmail}</p>
              </div>
            </div>
            <div className='row'>
              <label className='col-form-label col-12 col-sm-4'>Céges kapcsolattartó telefonszáma:</label>
              <div className='col-12 col-sm-8'>
                <p className='form-control-plaintext'>{phoneToString(customer.contactPhone)}</p>
              </div>
            </div>
            <div className='row'>
              <label className='col-form-label col-12 col-sm-4'>További telefonszámok:</label>
              <div className='col-12 col-sm-8'>
                {phones(customer.phones)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h3 className='mt-4 mb-2'>Cégadatok</h3>
    <div className='panel panel-default'>
      <div className='card-body'>
        <div className='form-horizontal row'>
          <div className='col-12 col-lg-6'>
            <div className='row'>
              <label className='col-form-label col-12 col-sm-4'>Adószám:</label>
              <div className='col-12 col-sm-8'>
                <p className='form-control-plaintext'>{customer.taxNumber}</p>
              </div>
            </div>
            <div className='row'>
              <label className='col-form-label col-12 col-sm-4'>Cégjegyzékszám:</label>
              <div className='col-12 col-sm-8'>
                <p className='form-control-plaintext'>{customer.registrationNumber}</p>
              </div>
            </div>
            <div className='row'>
              <label className='col-form-label col-12 col-sm-4'>Bankszámlaszám:</label>
              <div className='col-12 col-sm-8'>
                <p className='form-control-plaintext'>{customer.bankAccountNumber}</p>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-6'>
            <div className='row'>
              <label className='col-form-label col-12 col-sm-4'>Fő tevékenységi kör:</label>
              <div className='col-12 col-sm-8'>
                <p className='form-control-plaintext'>{customer.scopeOfBusiness}</p>
              </div>
            </div>
            <div className='row'>
              <label className='col-form-label col-12 col-sm-4'>Kézbesítési megbízottjának az azonosítására alkalmas
                  adatai:</label>
              <div className='col-12 col-sm-8'>
                <p className='form-control-plaintext'>{customer.authorizedPersonToAcceptService}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h3 className='mt-4 mb-2'>Címadatok</h3>
    <div className='panel panel-default'>
      <div className='card-body'>
        <div className='form-horizontal'>
          <div className='row'>
            <label className='col-form-label col-12 col-sm-4'>Székhely:</label>
            <div className='col-12 col-sm-8'>
              <p className='form-control-plaintext'>{addressToString(customer.address)}</p>
            </div>
          </div>
          <div className='row'>
            <label className='col-form-label col-12 col-sm-4'>Magyarországi fióktelep:</label>
            <div className='col-12 col-sm-8'>
              <p className='form-control-plaintext'>{addressToString(customer.branchAddress)}</p>
            </div>
          </div>
          <div className='row'>
            <label className='col-form-label col-12 col-sm-4'>Postafiók cím:</label>
            <div className='col-12 col-sm-8'>
              <p className='form-control-plaintext'>{customer.postboxAddress}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {licences(customer)}
    {documents(customer)}
    <h3 className='mt-4 mb-2'>Aláírók, meghatalmazottak</h3>
    {customer.companyInfo ? customer.companyInfo.map((item, index) => {
      return <div className={(index === 0) ? 'panel panel-default' : 'panel panel-default mt-4'} key={`owner_${index}`}>
        <div className='card-body'>
          <div className='form-horizontal'>
            <h4 className='mt-4 mb-2'>Alap kapcsolattartási adatok</h4>
            <div className='row ml-0'>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <label className='col-form-label col-12 col-sm-4'>Név:</label>
                  <div className='col-12 col-sm-8'>
                    <p className='form-control-plaintext'>{item.name}</p>
                  </div>
                </div>
                <div className='row'>
                  <label className='col-form-label col-12 col-sm-4'>Kapcsolattartó típus:</label>
                  <div className='col-12 col-sm-8'>
                    <p className='form-control-plaintext'>{item.type === 'signer' ? 'Aláíró' : 'Megbízott'}</p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <label className='col-form-label col-12 col-sm-4'>E-mail cím:</label>
                  <div className='col-12 col-sm-8'>
                    <p className='form-control-plaintext'>{item.email}</p>
                  </div>
                </div>
                <div className='row'>
                  <label className='col-form-label col-12 col-sm-4'>kapcsolatIPont telefonszám:</label>
                  <div className='col-12 col-sm-8'>
                    <p className='form-control-plaintext'>{phoneToString(item.phone)}</p>
                  </div>
                </div>
              </div>
            </div>
            <h4 className='mt-4 mb-2'>Személyes adatok</h4>
            <div>
              {personalData(item, false)}
            </div>
            <h4 className='mt-4 mb-2'>Címadatok</h4>
            <div className='row ml-0'>
              {addressData(item)}
            </div>
            {item.ssoUserId === ssoUserId
              ? <div>
                <h4 className='mt-4 mb-2'>Dokumentumok</h4>
                <div className='col'>
                  { item.identificationDocument ? <DocumentContainer component={Document} id={item.identificationDocument} /> : null }
                </div>
              </div> : null }
          </div>
        </div>
      </div>
    }) : null}
  </div>
</div>

const individualProfile = (customer) => <div>
  <h3 className='mt-4 mb-2'>Alap kapcsolattartási adatok</h3>
  <div className='panel panel-default'>
    <div className='card-body'>
      <div className='form-horizontal row'>
        <div className='col-12 col-lg-6'>
          <div className='row'>
            <label className='col-form-label col-12 col-sm-4'>Név:</label>
            <div className='col-12 col-sm-8'>
              <p className='form-control-plaintext'>{customer.name}</p>
            </div>
          </div>
          <div className='row'>
            <label className='col-form-label col-12 col-sm-4'>E-mail cím:</label>
            <div className='col-12 col-sm-8'>
              <p className='form-control-plaintext'>{customer.email}</p>
            </div>
          </div>
          <div className='row'>
            <label className='col-form-label col-12 col-sm-4'>kapcsolatIPont telefonszám:</label>
            <div className='col-12 col-sm-8'>
              <p className='form-control-plaintext'>{phoneToString(customer.ugyfelfiokPhone)}</p>
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-6'>
          <div className='row'>
            <label className='col-form-label col-12 col-sm-4'>További telefonszámok:</label>
            <div className='col-12 col-sm-8'>
              <div className='form-control-plaintext'>{phones(customer.phones)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h3 className='mt-4 mb-2'>Személyes adatok</h3>
  <div className='panel panel-default'>
    <div className='card-body form-horizontal'>
      <div className=''>
        {personalData(customer, true)}
      </div>
    </div>
  </div>
  <h3 className='mt-4 mb-2'>Címadatok</h3>
  <div className='panel panel-default'>
    <div className='card-body'>
      <div className='form-horizontal row'>
        {addressData(customer)}
      </div>
    </div>
  </div>
  <h3 className='mt-4 mb-2'>További adatok</h3>
  <div className='panel panel-default'>
    <div className='card-body'>
      <div className='form-horizontal'>
        <div className='row'>
          <label className='col-form-label col-12 col-sm-4'>Adóazonosító jel:</label>
          <div className='col-12 col-sm-8'>
            <p className='form-control-plaintext'>{customer.taxId}</p>
          </div>
        </div>
        <div className='row'>
          <label className='col-form-label col-12 col-sm-4'>Bankszámlaszám:</label>
          <div className='col-12 col-sm-8'>
            <p className='form-control-plaintext'>{customer.bankAccountNumber}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  {licences(customer)}
  {documents(customer)}
</div>

export const StaticProfile = (props) => {
  return props.customer.type === 'individual'
    ? individualProfile(props.customer)
    : companyProfile(props.customer, props.ssoUserId)
}

export default StaticProfile
