/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import { connect } from 'react-redux'
import Profile from '../components/Profile'
import MasterDataWrapper from '../../../containers/MasterDataWrapper'
import { push } from 'connected-react-router'
import { updateAction, createMultiPartPost } from '../../../store/update.js'
import { UserIsAuthenticated } from '../../index'
import { passwordChangeAction } from '../../Login/modules/login'

export const doProfileDataSend = (dispatch, profileData) => {
  dispatch(updateAction({
    endPoint: 'update/profileAndFiles',
    config: createMultiPartPost(profileData),
    alertTitle: 'Profil adatok módosítása',
    successAlertText: 'Az adatmódosítás feldolgozása megkezdődött. Hamarosan értesítést fog kapni az eredményről.',
    errorAlertText: 'Sikertelen adatmódosítás!',
    successURL: '/profil/view',
    errorURL: '/profil/edit'
  }))
}

// These props come from the application's
// state when it is started
const mapStateToProps = (state) => {
  return ({
    user: state.login.user
  })
}

const mapDispatchToProps = (dispatch) => {
  return ({
    onSaveData: (profileData) => doProfileDataSend(dispatch, profileData),
    onPasswordChange: () => {
      dispatch(passwordChangeAction())
      dispatch(push('/jelszo-csere'))
    }
  })
}

export default UserIsAuthenticated(MasterDataWrapper(connect(mapStateToProps, mapDispatchToProps)(UserIsAuthenticated(Profile))))
