import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { userName } from '../../utils/localization'
import { displayDistrib } from '../../utils/commonFunctions'

class HeaderComponent extends Component {
  render () {
    const { user, isAuthenticated, onLogoutClick, noSellSide, noBuySide } = this.props
    const location = this.props.location || { pathname : window.location.href.substring(window.location.origin.length) }
    const isSell = location.pathname.startsWith('/eladas') && !noSellSide
    const isBuy = location.pathname.startsWith('/vetel') && !noBuySide
    const isTopDarkRowMenuNeeded = location.pathname !== '/'

    const userLabel = isAuthenticated ? <span className='username'><NavLink to='/profil/view' >{userName(user, true)}</NavLink></span> : null

    const logoutClick =

      <span className='logout oi oi-account-logout' aria-hidden='true' onClick={() => onLogoutClick()} />

    // eslint-disable-next-line
    const apiUrlJs = API_URL_JS

    const commonMenuProfil = (toggleButton) => (isAuthenticated
      ? <li className={`nav-item mx-1 ml-sm-0 mr-sm-3 ${toggleButton ? '' : 'd-inline-block'}`}
        data-toggle={toggleButton ? 'collapse' : ''} data-target={toggleButton ? '#bottomMenu' : ''}>
        <NavLink to='/profil/view' activeClassName='header-active-link'>
          Profil
        </NavLink>
      </li>
      : null)

    const commonMenuHelp = (toggleButton) => (isAuthenticated
      ? <li className={`nav-item mx-1 ml-sm-0 mr-sm-1 ${toggleButton ? '' : 'd-inline-block'}`}
        data-toggle={toggleButton ? 'collapse' : ''} data-target={toggleButton ? '#bottomMenu' : ''}>
        <a href={`${apiUrlJs}/kapcsolatipontSugo`} target='_blank'>
          Súgó
        </a>
      </li>
      : null)

    const sellMenuAssignment = (toggleButton) => ((isSell && isAuthenticated)
      ? <li className={`nav-item mx-1 ml-sm-0 mr-sm-3 ${toggleButton ? '' : 'd-inline-block'}`}
        data-toggle={toggleButton ? 'collapse' : ''} data-target={toggleButton ? '#bottomMenu' : ''}>
        <NavLink to='/eladas/megbizasok' activeClassName='header-active-link'>
          Megbízások
        </NavLink>
      </li>
      : null)

    const sellMenuPresentation = (toggleButton) => ((isSell && isAuthenticated)
      ? <li className={`nav-item mx-1 ml-sm-0 mr-sm-3 ${toggleButton ? '' : 'd-inline-block'}`}
        data-toggle={toggleButton ? 'collapse' : ''} data-target={toggleButton ? '#bottomMenu' : ''}>
        <NavLink to='/eladas/bemutatasok' activeClassName='header-active-link'>
          Bemutatások
        </NavLink>
      </li>
      : null)

    const sellMenuBuyOffer = (toggleButton) => ((isSell && isAuthenticated)
      ? <li className={`nav-item mx-1 ml-sm-0 mr-sm-3 ${toggleButton ? '' : 'd-inline-block'}`}
        data-toggle={toggleButton ? 'collapse' : ''} data-target={toggleButton ? '#bottomMenu' : ''}>
        <NavLink to='/eladas/veteli-ajanlatok' activeClassName='header-active-link'>
          Vételi ajánlatok
        </NavLink>
      </li>
      : null)

    const buyMenuPresentation = (toggleButton) => ((isBuy && isAuthenticated)
      ? <li className={`nav-item mx-1 ml-sm-0 mr-sm-3 ${toggleButton ? '' : 'd-inline-block'}`}
        data-toggle={toggleButton ? 'collapse' : ''} data-target={toggleButton ? '#bottomMenu' : ''}>
        <NavLink to='/vetel/bemutatasok' activeClassName='header-active-link'>
          Bemutatások
        </NavLink>
      </li>
      : null)

    const buyMenuBuyOffer = (toggleButton) => ((isBuy && isAuthenticated)
      ? <li className={`nav-item mx-1 ml-sm-0 mr-sm-3 ${toggleButton ? '' : 'd-inline-block'}`}
        data-toggle={toggleButton ? 'collapse' : ''} data-target={toggleButton ? '#bottomMenu' : ''}>
        <NavLink to='/vetel/veteli-ajanlatok' activeClassName='header-active-link'>
          Vételi ajánlatok
        </NavLink>
      </li>
      : null)

    const menu = <div className='collapse navbar-collapse justify-content-end' id='bottomMenu'>
      <ul className='navbar-nav d-sm-none b-block'>
        {sellMenuAssignment(true)}
        {sellMenuPresentation(true)}
        {sellMenuBuyOffer(true)}
        {buyMenuPresentation(true)}
        {buyMenuBuyOffer(true)}
        {commonMenuProfil(true)}
        {commonMenuHelp(true)}
      </ul>
      <ul className='navbar-nav d-none d-sm-block'>
        {sellMenuAssignment(false)}
        {sellMenuPresentation(false)}
        {sellMenuBuyOffer(false)}
        {buyMenuPresentation(false)}
        {buyMenuBuyOffer(false)}
        {commonMenuProfil(false)}
        {commonMenuHelp(false)}
      </ul>
    </div>

    return <header className={isAuthenticated ? 'logon header-wrapper container-fluid px-0' : 'header-wrapper container-fluid px-0'}>
      {isAuthenticated && isTopDarkRowMenuNeeded
        ? <div className='header-top '>
          <div className='page-center-content px-3 '>
            <div className='row flex-row-reverse'>
              <div className='col-12 col-sm-6 text-right'>
                {userLabel} {logoutClick}
              </div>
              <div className='col-12 col-sm-6'>
                <div className='header-triangle-box'>
                  <NavLink to='/vetel/' activeClassName='header-active-triangle'>
                    Ingatlan vétel
                  </NavLink>
                </div>
                <div className='header-separator header-triangle-box' />
                <div className='header-triangle-box'>
                  <NavLink to='/eladas/' activeClassName='header-active-triangle'>
                    Ingatlan eladás
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        : (isAuthenticated ? <div className='header-top'>
          <div className='page-center-content px-3'>
            <div className='row'>
              <div className='col-12 col-sm-6' />
              <div className='col-12 col-sm-6 text-right'>
                {userLabel} {logoutClick}
              </div>
            </div>
          </div>
        </div>
          : null) }
      <div className='header container-fluid px-3'>
        <div className='page-center-content mt-2 mb-1'>
          <div className='row justify-content-center position-relative'>
            { // eslint-disable-next-line no-undef
              DISTRIB_TYPE === 'production' ? null : <div className='stamp'>{displayDistrib(DISTRIB_TYPE)}</div>
            }
            <div className={`logo position-relative ${isAuthenticated ? 'logo position-relative text-nowrap text-left col' : ''}`}>
              <NavLink to='/' className='logourl'>
                &nbsp;
              </NavLink>
              <div className='w-100 d-block d-sm-none' />
              <span className='header-name'><span className='header-bold'>KAPCSOLATI</span>PONT</span>
            </div>
            {isAuthenticated ? <div className='text-nowrap text-right align-self-end col'><nav className='navbar navbar-expand-sm navbar-dark no-gutter'>
              <div className='col-12 text-right no-gutter'>
                <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#bottomMenu' aria-controls='bottomMenu'
                  aria-expanded='false' aria-label='Toggle navigation'>
                  <span className='pi pi-bars' />
                </button>
                {menu}
              </div>
            </nav></div> : null}
          </div>
        </div>
      </div>
    </header>
  }
}

HeaderComponent.propTypes = {
  isAuthenticated : PropTypes.bool.isRequired,
  user            : PropTypes.object,
  onLogoutClick   : PropTypes.func.isRequired,
  location        : PropTypes.object,
  noBuySide       : PropTypes.bool,
  noSellSide      : PropTypes.bool
}

export default HeaderComponent
