/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */
import { createFetchAction } from './fetchReducer'

// ------------------------------------
// Event creators
// ------------------------------------
/**
 * Ez határozza meg, hogy a Redux store-ban a state.events alatt milyen kulccsal jelenjenek meg az események.
 * @param key
 * @param important
 * @param side
 * @param eventState
 * @param processId
 */
const statePath = (key, important, side, eventState, processId) =>
  side +
    (important !== undefined ? (important ? '-duty' : '-noDuty') : '-allDuty') +
    (eventState !== undefined ? '-' + eventState : '-allStates') +
    (processId !== undefined ? '-' + processId : '-allProcesses') +
    (key !== undefined ? '-' + key : '')

export const fetchEvents = (key, important, side, eventState, processId) => (
  createFetchAction(
    {
      endPoint: '/lists/events',
      statePath: 'events',
      queryParam: `?customerSide=${side}` +
        (eventState !== undefined ? '&eventState=' + eventState : '') +
        (processId !== undefined ? '&processId=' + processId : '') +
        (important !== undefined ? '&duty=' + (important ? 'true' : 'false') : ''),
      subPath: statePath(key, important, side, eventState, processId)
    })
)

// ------------------------------------
// Selectors
// ------------------------------------
export const selectEventFlags = (state, key, important, side, eventState, processId) => {
  const element = statePath(key, important, side, eventState, processId)
  if (state.fetch.events && state.fetch.events[element]) {
    return state.fetch.events[element]
  } else {
    return { fetched: {}, isLoaded: false, isLoading: false, error: '' }
  }
}

export const selectEvents = (state, key, important, side, eventState, processId) =>
  selectEventFlags(state, key, important, side, eventState, processId).fetched

export const markEventRead = (eventId) => {
  // eslint-disable-next-line no-undef
  fetch(API_URL_JS + '/lists/readEvent?eventId=' + eventId, { method: 'GET', credentials: 'include' })
    .then(resp => {})
}
