/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import fetchOnUpdate from '../utils/fetchOnUpdate'
import { createFetchAction } from '../store/fetchReducer'
import { connect } from 'react-redux'

export const fetchSummary = () => createFetchAction(
  {
    endPoint: '/lists/eventSummary',
    statePath: 'home',
    subPath: 'eventSummary'
  }
)

export const selectHome = state => {
  const isAuthenticated = state.login.isAuthenticated
  if (!isAuthenticated) {
    return { isLoaded: true, isLoading: false, hasError: false }
  }
  if (state.fetch.home && state.fetch.home.eventSummary) {
    const flags = state.fetch.home.eventSummary
    return { ...flags.fetched, isLoaded: flags.isLoaded, isLoading: flags.isLoading, error: flags.error }
  } else {
    return { isLoaded: false, isLoading: true, hasError: false }
  }
}

export const SummaryWrapper = (Child) => {
  class DecoratedComponent extends Component {
    componentDidMount () {
      this.summaryRefresh = setInterval(() => { this.props.actions.dispatchSummary() }, 15000)
    }

    componentWillUnmount () {
      if (this.summaryRefresh) {
        clearInterval(this.summaryRefresh)
      }
    }

    render () {
      return <Child {...this.props} />
    }
  }

  DecoratedComponent.propTypes = {
    actions: PropTypes.object
  }

  return connect(
    (state) => {
        const home = selectHome(state)
        const props = {
            user: state.login.user,
            ...home,
            isRefetchNeeded: state.login.isAuthenticated && ! home.isLoaded
        }
        return props
    },
    null,
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...ownProps,
      actions: {
        dispatchSummary: () => {
          stateProps.user && dispatchProps.dispatch(fetchSummary())
        }
      }
    })
  )(fetchOnUpdate([], (params, actions) => {
    actions.dispatchSummary()
  }, (props) => true, null, null, 'SummaryWrapper')(DecoratedComponent))
}
