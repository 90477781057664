/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 *
 * This implementation is inspired by: Nicola Molinari (emmenko) redux-react-router-async-example/lib/decorators/fetchOnUpdate.js
 */

import React from 'react'
import PropTypes from 'prop-types'
import shallowEqual from 'react-redux/lib/utils/shallowEqual'

function mapParams (paramKeys, params) {
  return paramKeys.reduce((acc, key) => {
    return Object.assign({}, acc, { [key]: params[key] })
  }, {})
}

export default function fetchOnUpdate (paramKeys, fn, needFetch, statusProp, ErrorComponent, debugName = 'NoName') {
  return DecoratedComponent =>
    class FetchOnUpdateDecorator extends React.Component {
      static propTypes = {
        actions: PropTypes.object,
        params: PropTypes.object,
        isRefetchNeeded: PropTypes.bool
      }

      static displayName = debugName + 'Fetcher'

      isFetchNeeded (props) {
        if (needFetch === undefined) {
          return true
        } else if (typeof needFetch === 'function') {
          return needFetch(props)
        } else if (typeof needFetch === 'string') {
          return props[needFetch]
        } else {
          console.log('Warning: isFetchNeeded must be a string property name or a function.')
          return false
        }
      }

      componentWillMount () {
        if (this.isFetchNeeded(this.props)) {
          fn(mapParams(paramKeys, this.props.match ? this.props.match.params : {}), this.props.actions)
        }
      }

      componentDidUpdate (prevProps) {
        const params = mapParams(paramKeys, this.props.match ? this.props.match.params : {})
        const prevParams = mapParams(paramKeys, prevProps.match ? prevProps.match.params : {})

        if (!shallowEqual(params, prevParams) && this.isFetchNeeded(this.props)) {
          fn(params, this.props.actions)
        }

        if (this.props.isRefetchNeeded) {
          fn(params, this.props.actions)
        }
      }

      render () {
        let render
        const { isLoading, isLoaded, hasError, error } = statusProp ? this.props[statusProp] : this.props
        if (hasError) {
          render = ErrorComponent ? <ErrorComponent {...this.props} /> : <div className='text-danger' >Hiba történt a letöltéskor: {error}</div>
        } else if (isLoading) {
          render = <div><span className='fa fa-spinner fa-pulse fa-4x fa-fw' /></div>
        } else if (isLoaded) {
          render = <DecoratedComponent {...this.props} />
        } else {
          render = <div><span className='fa fa-spinner fa-pulse fa-4x fa-fw' /></div>
        }
        return render
      }
    }
}
