/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import numeral from 'numeral'
import 'moment/locale/hu'
import * as moment from 'moment'
import { precisionRound } from './commonFunctions'

// load a locale
numeral.register('locale', 'hu', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: 'e',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal : function (number) {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: 'Ft'
  }
})

// switch between locales
numeral.locale('hu')

export const formatCurrency = (val, curr, precision) => {
  if (curr === 'HUF') {
    return numeral(val).format('0,0') + ' Ft'
  } else if (curr === 'EUR' && precision) {
    return numeral(val).format('0,0.00') + ' €'
  } else if (curr === 'EUR' && !precision) {
    return numeral(val).format('0,0') + ' €'
  } else {
    return numeral(val).format('0,0.00') + (curr || '')
  }
}

export const formatNumber = (val) => {
  return numeral(val).format('0,0')
}

moment.locale('hu')

export const formatDateTime = (val) => {
  return val === undefined || val === '' || val === null
    ? 'nincs dátum' : moment(val).format('YYYY. MMMM D. HH:mm')
}

export const formatDate = (val) => {
  let md = moment(val)
  return md.isValid() ? md.format('YYYY. MMMM DD.') : '-'
}

export const formatDateToCalendar = (val, empty) => {
  const noDate = empty === undefined ? 'nincs dátum' : empty
  return val === undefined || val === '' || val === null
    ? noDate : moment(val).format('YYYY.MM.DD.')
}

export const toJsonDate = (val) => {
  return val ? new Date(val).toJSON() : null
}

export const hu = {
  firstDayOfWeek: 1,
  dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
  dayNamesShort: ['vas', 'hét', 'kedd', 'sze', 'csüt', 'pén', 'szo'],
  dayNamesMin: ['V', 'H', 'K', 'Sz', 'Cs', 'P', 'Szo'],
  monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember', 'október', 'november', 'december'],
  monthNamesShort: ['jan', 'feb', 'márc', 'ápr', 'máj', 'jún', 'júl', 'aug', 'szept', 'okt', 'nov', 'dec']
}

export const dateFormat = 'yy.mm.dd.'

export const userName = (user, addCompany) => {
  let name = 'névtelen'
  if (user && user.customer && user.customer.type === 'individual') {
    if (user.customer.name) {
      name = (user.customer.compellation ? user.customer.compellation + ' ' : '') + user.customer.name
    }
  } else if (user && user.name) {
    name = user.name
    if (addCompany && user.customer.type === 'company' && (user.customer.shortenedCompanyName || user.customer.name)) {
      name += ' (' + (user.customer.shortenedCompanyName || user.customer.name) + ')'
    }
  }
  return name
}

export const humanFileSize = (bytes, si) => {
  var thresh = si ? 1000 : 1024
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }
  var units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  var u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)
  return bytes.toFixed(1) + ' ' + units[u]
}

export const mimeTypeToText = (mimeType) => {
  const parts = mimeType.split('/')
  return parts.length > 1 ? parts[1].toUpperCase() : 'ismeretlen'
}

export const romanize = (num) => {
  if (!+num) {
    return false
  }
  let digits = String(+num).split('')
  let key = ['', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM',
    '', 'X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC',
    '', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX']
  let roman = ''
  let i = 3
  while (i--) { roman = (key[+digits.pop() + (i * 10)] || '') + roman }
  return Array(+digits.join('') + 1).join('M') + roman
}

export const convertLevel = (floor) => {
  switch (floor) {
    case 'underground4':
      return '-4. szint'
    case 'underground3':
      return '-3. szint'
    case 'underground2':
      return '-2. szint'
    case 'underground1':
      return '-1. szint'
    case 'basement':
      return 'alagsor'
    case 'suteren':
      return 'szuterén'
    case 'halfSuteren':
      return 'félszuterén'
    case 'downstairs':
      return 'földszint'
    case 'highDownstairs':
      return 'magasföldszint'
    case 'halfUpstairs':
      return 'félemelet'
  }
  return floor || ''
}

export const floorAndDoor = (floor, door) => {
  let format = ''
  format += floor && Number.parseInt(floor) ? romanize(floor) : convertLevel(floor)
  format += format.length > 0 ? '/' : ''
  format += door || 'nincs adat'
  return format
}

export const convertPaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case 'transfer':
      return 'Átutalás'
      //    case 'debitCard':
      //      return 'Bankkártya'
    case 'bankPayment':
      return 'Banki befizetés'
  }
  return paymentMethod || ''
}

export const trueFalseOptions = [
  { label: '', value: null },
  { label: 'Igen', value: true },
  { label: 'Nem', value: false }
]

export const roundByCurrency = (val, currency) => {
  return currency === 'HUF' ? precisionRound(val, 0) : precisionRound(val, 0)
}

export const calculatePercentage = (value, wholeAmount) => (Math.ceil((value / wholeAmount) * 10000) / 100).toFixed(2)

export const calculateValue = (percentage, wholeAmount) => Math.ceil(wholeAmount * (percentage / 100))
