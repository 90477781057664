/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */
import React from 'react'
import Popup from '../components/Common/UFPopup/index'
import { push } from 'connected-react-router'
import UpdateDelayPopup from '../components/Common/UpdateDelayPopup'

export const UPDATE_SUCCESS = 'UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'UPDATE_FAILURE'

const receiveUpdate = (endpoint, data) => {
  return {
    type: UPDATE_SUCCESS,
    endpoint,
    data,
    errorMessage: undefined
  }
}

const updateError = (errorCode, errorMessage) => {
  return {
    type: UPDATE_FAILURE,
    errorCode,
    errorMessage
  }
}

export const createPost = (data) => (
  {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type':'application/json; charset=utf-8' },
    body: JSON.stringify(data)
  }
)

export const createMultiPartPost = (data) => (
  {
    method: 'POST',
    credentials: 'include',
    body: data
  }
)

export const createFormPost = (data) => (
  {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' },
    body: data
  }
)

export const updateAction = ({ endPoint, config, alertTitle, successAlertText, errorAlertText, successURL, errorURL, data } = {}) => {
  return (dispatch) => {
    document.getElementById('dimmer').style.display = 'block'
    document.getElementById('dimmer_text').textContent = 'Kérem várjon!'
    // eslint-disable-next-line no-undef
    return fetch(API_URL_JS + (endPoint.startsWith('/') ? '' : '/') + endPoint, config)
      .then(response => {
        document.getElementById('dimmer').style.display = 'none'
        if (response.ok) {
          response.json().then(
              waitForEvent => {
                  if (waitForEvent !== true) {
                      Popup.create({
                          title: alertTitle,
                          content:
                              <UpdateDelayPopup
                                waitForEvent={waitForEvent}
                                closePopup={() => {
                                  if (successURL) {
                                    dispatch(push(successURL))
                                  }
                                  dispatch(receiveUpdate(endPoint, data))
                                  Popup.close()
                                }}
                              />,
                          buttons: {}
                      })
                  } else {
                      Popup.create({
                          title: `${alertTitle}`,
                          content: <div className='alert'>{successAlertText}</div>,
                          buttons: {
                              center: [{
                                  text: 'Rendben',
                                  className: 'btn green',
                                  action: () => {
                                      if (successURL) {
                                          dispatch(push(successURL))
                                      }
                                      dispatch(receiveUpdate(endPoint, data))
                                      Popup.close()
                                  }
                              }]
                          }
                      })
                  }
              }
          )
        } else {
          response.text().then(
            text => {
              if (response.status === 422) {
                  // HttpStatus.UNPROCESSABLE_ENTITY: speciális eset, amikor a vételi ajánlat adása közben megváltoznak a birtokba adási feltételek
                  Popup.create({
                      title: `Vételi ajánlat nem küldhető el`,
                      content: <div className='alert'>
                          A birtokba adási feltételek megváltoztak.
                          Kérem, frissítse az oldalt és ellenőrzizze a feltételeket!
                      </div>,
                      buttons: {
                          center: [{
                              text: 'Rendben',
                              className: 'btn green',
                              action: () => {
                                  Popup.close()
                              }
                          }]
                      }
                  })
              } else {
                  Popup.create({
                      title: `${alertTitle}`,
                      content: <div className='alert'>{errorAlertText} {text}</div>,
                      buttons: {
                          center: [{
                              text: 'Rendben',
                              className: 'btn green',
                              action: () => {
                                  if (errorURL) {
                                      dispatch(push(errorURL))
                                  }
                                  dispatch(updateError(undefined, 'Sikertelen mentés.'))
                                  Popup.close()
                              }
                          }]
                      }
                  })
              }
            }
          )
        }
      },
      err => console.log('Error: ', err)
      ).catch(
        err => console.log('Error: ', err)
      ).finally(() => document.getElementById('dimmer').style.display = 'none')
  }
}
