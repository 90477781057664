/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

export default {
  SHOW    : 'SHOW',
  CLOSE   : 'CLOSE',
  REFRESH : 'REFRESH'
}
