/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import { connect } from 'react-redux'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { fetchEntity, getDocumentById } from '../store/entity'
import fetchOnUpdate from '../utils/fetchOnUpdate'

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: { onLoadEntity: (entityType, entityId) => dispatch(fetchEntity('document', ownProps.id)) }
})

const mapStateToProps = (state, ownProps) => {
  return getDocumentById(state, ownProps.id)
}

const DocumentError = (props) => <div>Nem található a dokumentum: {props.id}</div>
DocumentError.propTypes = {
  id: PropTypes.number
}

class DocumentWrapper extends Component {
  render () {
    return React.createElement(this.props.component, { ...this.props })
  }
}

DocumentWrapper.propTypes = {
  component: PropTypes.any
}

export default connect(mapStateToProps, mapDispatchToProps)(
    fetchOnUpdate([], (params, actions) => {
      actions.onLoadEntity()
    }, 'isFetchNeeded', null, DocumentError)
    (DocumentWrapper)
)
