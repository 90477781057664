/*
 * Copyright 2019 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import Phone from '../../../components/Common/Phone'
import { Button } from 'primereact/components/button/Button'
import PropTypes from 'prop-types'
import React from 'react'

export function PhoneListEditor(props)
{
  return <div className='form-row phone'>
    <div className='col-7'>
      <Phone name={'phone' + (props.index
      )}
      countryCode={props.customer.phones[props.index].countryCode}
      phoneNumber={props.customer.phones[props.index].phoneNumber}
      masterData={props.masterData}
      updatePhone={props.updatePhone}/>
    </div>
    <div className='col-2'>
      <Button className='btn green' label='Töröl' onClick={props.onClick}/>
    </div>
  </div>
}

PhoneListEditor.propTypes = {
  index: PropTypes.any,
  customer: PropTypes.any,
  masterData: PropTypes.any,
  updatePhone: PropTypes.func,
  onClick: PropTypes.func
}
