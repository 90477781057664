/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import { connect } from 'react-redux'
import Remark from '../components/Remark'
import MasterDataWrapper from '../../../containers/MasterDataWrapper'
import { createPost, updateAction } from '../../../store/update'

// These props come from the application's
// state when it is started
const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  // Calls the API to get a token and
  // dispatches actions along the way
  return {
    onRemarkSend: ({ remark }) => {
      const remarkToSend = {
        remark,
        url: ownProps.match.params.url
      }
      dispatch(updateAction({
        endPoint: 'update/sendRemark',
        config: createPost(remarkToSend),
        alertTitle: 'Vélemény küldése',
        successAlertText: 'A véleményét elküldtük az OTPIP felé.',
        errorAlertText: 'Sikertelen vélemény küldés! Kérjük ismételje meg később!',
        successURL: '/',
        errorURL: '/'
      }))
    }
  }
}

export default MasterDataWrapper(connect(mapStateToProps, mapDispatchToProps)(Remark))
