/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */
import React from 'react'

export const helpIcon = <div className='help-icon'>
  <span>
    <i className='fa fa-question-circle-o fa-1x' />
  </span>
</div>

export const exclamationIcon = <span className='fa-stack'>
  <i className='fa fa-circle-thin fa-stack-2x' />
  <i className='fa fa-exclamation fa-stack-1x' />
</span>

export const warningIcon = <div className='warning-icon'>
  {exclamationIcon}
</div>

export const infoIcon = <span className='fa-stack'>
  <i className='fa fa-circle-thin fa-stack-2x' />
  <i className='fa fa-info fa-stack-1x' />
</span>

export const negativeAddressIcon = <span className='fa-stack icon-negative'>
  <i className='fa fa-address-card-o fa-stack-1x' />
</span>

export const positiveAddressIcon = <span className='fa-stack icon-positive'>
  <i className='fa fa-address-card-o fa-stack-1x' />
</span>

export const addressIcon = <span className='fa-stack'>
  <i className='fa fa-address-card-o fa-stack-1x' />
</span>

export const positivePasswordIcon = <span className='fa-stack icon-positive'>
  <i className='fa fa-key fa-stack-1x' />
</span>

export const negativePasswordIcon = <span className='fa-stack icon-negative'>
  <i className='fa fa-key fa-stack-1x' />
</span>

export const warningPasswordIcon = <span className='fa-stack icon-warning'>
  <i className='fa fa-key fa-stack-1x' />
</span>

export const positiveEnvelopeIcon = <span className='fa-stack icon-positive'>
  <i className='fa fa-envelope-o fa-stack-1x' />
</span>
