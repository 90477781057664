/*
 * Copyright 2020 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */
import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/components/button/Button'
import SimpleEvent from '../Event/SimpleEvent'

const fetchData = (eventData) => {
	const config = {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type':'application/json; charset=utf-8' },
		body: JSON.stringify(eventData)
	}
	// eslint-disable-next-line no-undef
	return fetch(API_URL_JS + '/update/eventWait', config)
}

const UpdateDelayPopup = ({ closePopup, waitForEvent, waitSeconds=20 }) => {
	const [secondsToWait, setSecondsToWait] = useState(waitSeconds)
	const [fetching, setFetching] = useState(false)
	const [userEvent, setUserEvent] = useState(null)

	useEffect(() => {
		const interval = setInterval(() => {
			setSecondsToWait(seconds => seconds -1)
			if (!fetching) {
				setFetching(true)
				fetchData(waitForEvent)
				  .then(response => {
					if (response.ok) {
						response.json().then(event => {
							setUserEvent(event)
							clearInterval(interval)
						})
					}
				  })
				  .finally(() => setFetching(false))
			}
		}, 1000)
		return () => {
			clearInterval(interval)
		}
	}, [])

	return <div className='smsPassword-box'>
		{ secondsToWait >= 1 && !userEvent ?
			<>
				<div>Feldolgozás folyamatban...</div>
				<div><i className="fa fa-spinner fa-pulse fa-4x fa-fw"></i></div><div>Még { secondsToWait } másodperc</div>
				<div/>
			</>
			: null }
		{ secondsToWait < 1 && ! userEvent ?
			<div>Nem érkezett meg a feldolgozás eredménye. Pár perc múlva a nyitólapon ellenőrizze a beérkezett üzeneteket!</div> : null }
		{ userEvent ? <SimpleEvent event={userEvent.event} /> : null }

		{ secondsToWait < 1 || userEvent ?
			<>
				<div/>
				<div><Button className='btn green' label='Rendben' onClick={(event) => closePopup()} /></div>
			</>
			: null
		}
	</div>
}

export default UpdateDelayPopup
