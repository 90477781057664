import 'whatwg-fetch'
import { push } from 'connected-react-router'
import { LOGIN_SUCCESS, doLogin, getGlobalOtpipAuth, setGlobalOtpipAuth } from '../routes/Login/modules/login'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

/**
 * Ha egy action hármas közepén kiderül, hogy nincs authentikáció akkor
 * ebben az objektumban kerül tárolása az action, átírányítunk a bejelentkezésre
 * majd sikeres bejelentkezés után újra lefuttatjuk az action hármast.
 */
let unsuccessfulAction = {}
let reactsession

// Fetches an API response and normalizes the result JSON.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = (endpoint, state, options = {}) => {
  const fullUrl = endpoint

  const defaultOptions = {}
  defaultOptions.headers = {}
  defaultOptions.method = 'GET'
  defaultOptions.credentials = 'include'

  if (reactsession !== undefined && reactsession !== null) {
    cookies.set('JSESSIONID', reactsession, { path: '/' })
  }

  return fetch(fullUrl, Object.assign({}, defaultOptions, options))
    .then(response => {
      const session = response.headers.get('reactsession')
      if (reactsession !== session) {
        console.log('New session started: ' + session)
        reactsession = session
      }
      return response.text().then(text => {
        let json
        try {
          json = JSON.parse(text)
        } catch (e) {
          json = { jsonParseError: e }
        }
        if (!response.ok) {
          json = { statusCode: response.status, ...json }
          return Promise.reject(json)
        }

        return Object.assign({}, json)
      })
    },
    errorResponse => Promise.reject(errorResponse)
    )
}

// Az action-be ezt kell betenni ha ezen a middleware-en
// keresztül akarjuk futtatni a lekérést.
export const CALL_API = Symbol('Call API')

export default store => next => action => {
  let insideAction = action
  let redirectUrlAfterSuccessFetch = ''
  let resultWith = {}
  if (insideAction.type === LOGIN_SUCCESS && unsuccessfulAction.action) {
    insideAction = unsuccessfulAction.action
    redirectUrlAfterSuccessFetch = unsuccessfulAction.url
    delete unsuccessfulAction.action
    delete unsuccessfulAction.url
    resultWith = next(action)
  }

  const callAPI = insideAction[CALL_API]
  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  if (callAPI.redirectUrlAfterSuccess) {
    redirectUrlAfterSuccessFetch = callAPI.redirectUrlAfterSuccess
  }
  let { endpoint } = callAPI
  const { types, options } = callAPI

  const currentState = store.getState()

  if (typeof endpoint === 'function') {
    endpoint = endpoint(currentState)
  }
  checkVariables(endpoint, types)

  const actionWith = data => {
    const finalAction = Object.assign({}, insideAction, data)
    delete finalAction[CALL_API]
    return finalAction
  }

  // if (!userIsAuthenticated(currentState)) {
  //   return Object.assign(resultWith, redirect(currentState, insideAction, store.dispatch))
  // }

  const [ requestType, successType, failureType ] = types
  next(actionWith({ ...callAPI, type: requestType }))

  return callApi(endpoint, currentState, options).then(
    response => {
      let resultState = Object.assign(resultWith, next(actionWith({
        ...callAPI,
        response,
        type: successType
      })))
      if (redirectUrlAfterSuccessFetch !== '') {
        store.dispatch(push(redirectUrlAfterSuccessFetch))
      }
      return resultState
    },
    error => {
      if (error.statusCode === 401 || error.statusCode === 403) {
        console.log('callAPI error: ' + error.statusCode)
        return Object.assign(resultWith, redirect(currentState, insideAction, store.dispatch))
      } else {
        return Object.assign(resultWith, next(actionWith({
          ...callAPI,
          type: failureType,
          error: error.message || 'A keresett információ nem elérhető.'
        })))
      }
    }
  )
}

/**
 * A kapott paraméterek ellenőrzése, hogy megfelelnek-e az elvárásoknak
 *
 * @param endpoint az url (string-nek kell lennie)
 * @param types az action típusok (három elemű string tömbnek kell lennie)
 */
export const checkVariables = (endpoint, types) => {
  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.')
  }
}

export const redirect = (currentState, action, dispatch) => {
  const path = getCurrentPath(currentState)

  if (path !== '/login') {
    unsuccessfulAction.action = action
    unsuccessfulAction.url = getCurrentPath(currentState)
  }

  dispatch(push('/login'))
  return {}
}

export const userIsAuthenticated = (currentState, dispatch) => {
  const otpipAuth = cookies.get(COOKIE_NAME) // eslint-disable-line no-undef
  if (otpipAuth === undefined) {
    setGlobalOtpipAuth(otpipAuth)
    return false
  }
  const globalOtpipAuth = getGlobalOtpipAuth()
  if (otpipAuth !== globalOtpipAuth) {
    // változott az SSO bejelentkezett felhasználó
    setGlobalOtpipAuth(otpipAuth)
    if (otpipAuth !== undefined) {
      doLogin(dispatch)
      // TODO sikerült-e a login
      return true
    } else {
      return false
    }
  } else {
    // return currentState !== undefined && currentState.login !== undefined ? (currentState.login.user !== undefined) : false
    return otpipAuth !== null && otpipAuth !== undefined
  }
}

export const getUser = (state) => {
  return state.login ? state.login.user : {}
}

export const getCurrentPath = (state) => {
  return state.router.location.pathname
}
