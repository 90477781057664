/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { passwordChangeAction, doLogin, forgotAction, normalLoginAction } from '../modules/login'
import LoginForm from '../components/LoginForm'

// These props come from the application's
// state when it is started
const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  errorMessage: state.login.errorMessage,
  errorCode: state.login.errorCode,
  mode: state.login.mode,
  ssoUserId: state.login.user ? state.login.user.ssoUserId : undefined
})

const mapDispatchToProps = (dispatch) => ({
  onLoginClick: (creds) => doLogin(dispatch, creds),
  onForgotClick: () => dispatch(forgotAction()),
  onChangeClick: () => dispatch(passwordChangeAction()),
  onLoginNormal: () => dispatch(normalLoginAction()),
  redirectToRoot: () => {
    dispatch(normalLoginAction())
    dispatch(push('/'))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)

