import { connect } from 'react-redux'
import HeaderComponent from './HeaderComponent'
import { getUser } from '../../store/api'
import { doLogout } from '../../routes/Login/modules/login'
import { SummaryWrapper } from '../../containers/SummaryWrapper'

// These props come from the application's
// state when it is started
const mapStateToProps = (state, dispatch) => {
  return {
    isAuthenticated: state.login.isAuthenticated,
    user: getUser(state),
    location: state.router.location
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLogoutClick: () => doLogout(dispatch)
})

export default SummaryWrapper(connect(mapStateToProps, mapDispatchToProps)(HeaderComponent))
