/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */
import moment from 'moment'
import { formatDateToCalendar } from './localization'

export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export const oMap = (obj, f) => Object.assign(...Object.keys(obj).map(k => ({ [k]: f(obj[k]) })))

export const addressToString = (address) => {
  if (typeof address === 'string') {
    return address
  } else {
    return address
      ? (address.country === 'Magyarország' || !address.country
        ? ((address.zip ? address.zip + ' ' : '') +
          (address.settlement ? address.settlement + ', ' : '') +
          (address.publicDomainName ? address.publicDomainName + ' ' : '') +
          (address.afterSettlement ? address.afterSettlement + ' ' : '') +
          (address.lotNumber ? 'HRSZ: ' + address.lotNumber + ' ' : '')).trim()
        : address.foreignAddress + ', ' + address.country)
      : null
  }
}

export const phoneToString = (phone) => {
  if (typeof phone === 'string') {
    return phone
  } else {
    return phone ? ((phone.countryCode ? '+' + phone.countryCode + ' ' : '') +
      (phone.phoneNumber ? phone.phoneNumber : '')).trim()
      : null
  }
}

export const iHelp = (masterData, key) => {
  const md = masterData.FormField ? masterData.FormField[key][0] : undefined
  return md ? (md.content ? md.content : md.description) : ''
}

const assignmentTypeMap = {
  assignmentMarket: 'eladó',
  assignmentForRent: 'kiadó',
  assignmentNpl: 'eladó NPL',
  assignmentOtpGroupUsed: 'OTP csoport használt ingatlan',
  assignmentOtpGroupNew: 'projekt (OTP csoport új) ingatlan',
  assignmentAuction: 'árverési megbízás'
}

export const assignmentTypeDisplay = (assignmentType, offerAssignmentType) => {
  return (assignmentTypeMap[assignmentType] ? assignmentTypeMap[assignmentType] : assignmentType) +
    (['exclusive', 'exclusiveWithHalfExclusive', 'halfExclusive'].includes(offerAssignmentType) ? ' (kizárólagos)' : '')
}

export const businessSubProcessStatusDisplay = (status) => {
  const display = {
    initialized: 'folyamatban',
    closed: 'befejeződött',
    expired: 'lejárt'
  }[status]
  return display || '-'
}

export const businessSubProcessEndStatusDisplay = (status) => {
  const display = {
    successful: 'sikeres',
    unsuccessful: 'sikertelen'
  }[status]
  return display || '-'
}

export const buyOfferDecisionDisplay = (decision) => {
  const display = {
    accept: 'elfogadva',
    deny: 'elutasítva',
    contraOffer: 'ellenajánlat',
    acceptContraOffer: 'ellenajánlat elfogadva',
    modifyContraOffer: 'ellenajánlat módosítva'
  }[decision]
  return display || '-'
}

export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, 'g'), replace)
}

export const cloneEvent = (ev, val) => {
  const evm = {
    ...ev,
    stopPropagation: ev.stopPropagation,
    preventDefault: ev.preventDefault,
    target: { ...ev.target, name: ev.target.name, value: val }
  }
  return evm
}

export const displayDistrib = (distribType) => {
  switch (distribType) {
    case 'dev':
      return 'FEJLESZTŐI'
    case 'scrtest':
      return 'SCR'
    case 'test':
      return 'TESZT'
    case 'instructor':
      return 'OKTATÓ'
    case 'production':
      return ''
    default:
      return 'EGYÉB'
  }
}

export const currencyExchange = (masterData, from, to, amount) => {
  if (from === to) {
    return amount
  } else if ((from === 'EUR' && to === 'HUF') || (from === 'HUF' && to === 'EUR')) {
    const md = masterData.RateOfExchange ? masterData.RateOfExchange['RateOfExchange_EUR_HUF'][0] : null
    const rate = md ? (md.content ? md.content.rate : null) : null
    return rate && amount ? (from === 'EUR' ? precisionRound(amount * rate, 0) : precisionRound(amount / rate, 0)) : null
  } else {
    return null
  }
}

export const differenceDatesInDays = (date1, date2, append) => {
  const d1 = formatDateToCalendar(date1)
  const d2 = formatDateToCalendar(date2)
  return moment(d1, 'YYYY.MM.DD.').diff(moment(d2, 'YYYY.MM.DD.'), 'days')
}

export const differenceDatesInDaysAppend = (date1, date2, text) => {
  const value = differenceDatesInDays(date1, date2)
  return !isNaN(value) ? (value + (text || '')) : '-'
}

export const precisionRound = (number, precision) => {
  let factor = Math.pow(10, precision)
  return Math.round(number * factor) / factor
}

export const buyOfferIdDisplay = (buyOfferId, showPresentation) => {
  if (buyOfferId) {
    return !buyOfferId.startsWith('F_') ? buyOfferId : (showPresentation ? buyOfferId.split('_')[1] : '')
  } else {
    return ''
  }
}

export const getWholeAmount = (masterData, limitSource, value, valueCurrency, advertisementPrice) => {
  const val = (limitSource && limitSource !== 'offeredByCustomerPrice')
    ? currencyExchange(masterData, advertisementPrice.currency, valueCurrency, advertisementPrice.value)
    : value
  return val || 0
}

export const arrayToString = (array, separator) => {
  let ret = ''
  array.forEach(e => { ret += (ret.length > 0 ? separator : '') + e.toString() })
  return ret
}

export const phoneCountryCode = /^\+\d{1,5}$/

export const phoneNumber = /^[\d+-/()\s]{6,20}$/

export const sendPercentToParse = (str) => { return !(/[0-9]+,0* %/.test(str)) }

export const isAfter320 = (buyBusinessId) => {
  let toCheck = 0
  // eslint-disable-next-line no-undef
  switch (DISTRIB_TYPE) {
    case 'dev':
      toCheck = 5327
      break
    case 'scrtest':
      toCheck = 8002
      break
    case 'test':
      toCheck = 28356
      break
    case 'instructor':
      toCheck = 5436
      break
    case 'production':
      toCheck = 33073
      break
    default:
      return 'EGYÉB'
  }
  // eslint-disable-next-line no-undef
  if (buyBusinessId.startsWith('V')) {
    let buyNumber = parseInt(buyBusinessId.substring(1))
    return buyNumber >= toCheck
  } else {
    return true
  }
}
