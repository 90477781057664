/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RemarkLink from '../../../components/Remark/RemarkLink'
import { formatDateTime } from '../../../utils/localization'
import { notificationIcon } from '../modules/notification'
import { Button } from 'primereact/components/button/Button'
import { markEventRead } from '../../../store/events'

class Notifications extends Component {
  constructor () {
    super()
    this.state = { readNotifications: [] }
  }

  handleClick = (ev, eventId) => {
    ev.preventDefault()
    ev.stopPropagation()
    markEventRead(eventId)
    this.setState({ readNotifications: this.state.readNotifications.concat(eventId) })
  }

  render () {
    const { events } = this.props
    const newEventsList = events['newEvent']
    const noticedEventsList = events['noticedEvent']

    return <div className='notification-list'>
      <h1 className='mt-5 mb-4'>Értesítések</h1>
      {newEventsList && newEventsList.length > 0
        ? <div className='primary-tab'>
          <h2 className='event-header-primary'>Új értesítések</h2>
          <table className='table table-hover'>
            <tbody>
              {newEventsList.map((event, index) => {
                let disabled = this.state.readNotifications.includes(event.eventId)
                return <tr key={index} className={(index % 2) ? 'table-odd' : 'table-even'}>
                  <td className='pr-1 icon-column'>{notificationIcon(event.eventType)}</td>
                  <td>
                    <div className='row align-items-center'>
                      <div className='col-12 col-md-7 col-lg-8 pl-0'>
                        {event.eventMessage}
                      </div>
                      <div className='col-12 col-md-5 col-lg-4 pl-0 pl-md-3'>
                        <span className=''><b>{formatDateTime(event.created)}</b></span>
                      </div>
                    </div>
                  </td>
                  <td className='text-right pl-0'>
                    <Button
                      label='Elolvastam'
                      disabled={disabled}
                      className={disabled ? 'btn green ui-state-disabled' : 'btn green'}
                      onClick={(ev) => this.handleClick(ev, event.eventId)}/>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
        : null
      }
      {noticedEventsList && noticedEventsList.length > 0
        ? <div className='primary-tab'>
          <h2 className='event-header-secondary'>Korábbi értesítések</h2>
          <table className='table table-hover'>
            <tbody>
              {noticedEventsList.map((event, index) => {
                return <tr key={index} className={(index % 2) ? 'table-odd' : 'table-even'}>
                  <td className='pr-1 icon-column'>{notificationIcon(event.eventType)}</td>
                  <td>
                    <div className='row align-items-center'>
                      <div className='col-12 col-md-7 col-lg-8 pl-0'>
                        {event.eventMessage}
                      </div>
                      <div className='col-12 col-md-5 col-lg-4 pl-0 pl-md-3'>
                        <span className=''><b>{formatDateTime(event.created)}</b></span>
                      </div>
                    </div>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
        : null}
      <div className='row'>
        <RemarkLink />
      </div>
    </div>
  }
}

Notifications.propTypes = {
  events: PropTypes.object
}

export default Notifications
