/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { form, from } from 'react-inform'
import { iHelp } from '../../../utils/commonFunctions'
import { Button } from 'primereact/components/button/Button'
import { InputTextarea } from '../../../components/Common/UFPrimeReact/UFInputTextarea'
import { warningIcon } from '../../../components/Common/Icons'
import LinkButton from '../../../components/Common/LinkButton'

const exists = v => Boolean(v)

const rulesMap = {
  remark: {
    'Kérjük írja le a véleményét!': exists
  }
}
class Remark extends Component {
  constructor () {
    super()

    this.state = { remark: '' }
    this.setValue = this.setValue.bind(this)
  }

  setValue (ev) {
    this.setState({ [ev.target.name]: ev.target.value })
    const { remark } = this.props.fields
    remark.props.onChange(ev.target.value)
  }

  render () {
    const { remark } = this.props.fields

    return (<div className='container'>
      <h1 className='page-header mt-5 mb-4'>Észrevétel</h1>
      <p className='mb-2'>
        Kérem, írja meg véleményét az alábbi szövegmezőben!
        <span className='icon-question-green'>
          <i className='fa fa-question-circle-o fa-1x' />
        </span>
      </p>
      <form className='form-horizontal'>
        <div className='form-row'>
          <div className='form-group col-12'>
            <InputTextarea id='remark' className='form-control' name='remark' maxLength={500}
              ufInputTextareaClassName='ufInputTextarea' counterClassName='counter'
              value={this.state.remark}
              onChange={this.setValue}
              helptext={iHelp(this.props.masterData, 'uf_uzenet')}
              helptextclassname='help-message-bottom'
            />
            <div className={remark.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
              <span className='warning-text'>{remark.error}</span></div>
          </div>
        </div>
        <div className='form-group'>
          <Button className='btn green' label='Vélemény küldése' onClick={(event) => this.handleForm(event)} />
          <LinkButton className='btn white' label='Mégsem' to='/' />
        </div>
      </form>
    </div>)
  }

  handleForm (event) {
    event.preventDefault()
    event.stopPropagation()

    const { form } = this.props
    form.forceValidate()
    if (form.isValid()) {
      const { onRemarkSend } = this.props
      onRemarkSend(this.state)
      this.setState({})
    }
  }
}

Remark.propTypes = {
  onRemarkSend : PropTypes.func,
  form: PropTypes.object,
  fields: PropTypes.array,
  masterData: PropTypes.object
}

Remark = form(from(rulesMap))(Remark)
export default Remark
