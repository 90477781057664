/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { helpIcon } from '../Icons'

export class InputTextarea extends Component {
  static defaultProps = {
    autoResize: false,
    maxLength: null,
    ufInputTextareaClassName: null,
    counterClassName: null
  }

  static propTypes = {
    autoResize: PropTypes.bool,
    cols: PropTypes.number,
    rows: PropTypes.number,
    className: PropTypes.string,
    ufInputTextareaClassName: PropTypes.string,
    counterClassName: PropTypes.string,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
    helptext: PropTypes.string,
    helptextclassname: PropTypes.string
  }

  constructor (props) {
    super(props)

    this.state = { leftChars: props.maxLength }

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)
  }

  onFocus (e) {
    if (this.props.autoResize) {
      this.resize()
    }
    if (this.props.helptext) {
      this.helpElement.style.display = 'block'
    }
  }

  onBlur (e) {
    if (this.props.autoResize) {
      this.resize()
    }
    if (this.props.helptext) {
      this.helpElement.style.display = 'none'
    }
  }

  onKeyUp (e) {
    if (this.props.autoResize) {
      this.resize()
    }
    if (this.props.maxLength !== null) {
      this.setState({ leftChars: this.props.maxLength - e.target.value.length })
    }
  }

  resize () {
    let linesCount = 0
    let lines = this.textareaElement.value.split('\n')

    for (let i = lines.length - 1; i >= 0; --i) {
      linesCount += Math.floor((lines[i].length / parseInt(this.props.cols, 10)) + 1)
    }

    this.textareaElement.rows = (linesCount >= parseInt(this.props.rows, 10)) ? (linesCount + 1) : parseInt(this.props.rows, 10)
  }

  render () {
    let styleClass = classNames('ui-inputtext ui-corner-all ui-state-default ui-widget', this.props.className, {
      'ui-state-disabled': this.props.disabled
    })

    // eslint-disable-next-line no-unused-vars
    let { counterClassName, ufInputTextareaClassName, autoResize, ...otherTextAreaProps } = this.props

    const textarea = <div>
      <textarea {...otherTextAreaProps} className={styleClass} ref={(input) => { this.textareaElement = input }}
        onFocus={this.onFocus} onBlur={this.onBlur} onKeyUp={this.onKeyUp} />
      {this.props.helptext
        ? <div ref={(e) => { this.helpElement = e }} className={classNames('help-message', this.props.helptextclassname)}
          style={{ display: 'none' }}>{helpIcon}
          <div className='help-text' dangerouslySetInnerHTML={{ __html: this.props.helptext }} /></div>
        : null}
    </div>

    let rendered
    this.props.maxLength !== null ? rendered = <div className={this.props.ufInputTextareaClassName}>
      <div className={this.props.counterClassName}>{this.state.leftChars}</div><div>{textarea}</div></div>
      : rendered = textarea

    return rendered
  }
}

export default InputTextarea
