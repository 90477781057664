import PropTypes from 'prop-types'
import React, { Component } from 'react'
import RemarkLink from '../../../components/Remark/RemarkLink'
import StaticProfile from './StaticProfile'
import { Button } from 'primereact/components/button/Button'
import IndividualProfile from './IndividualProfile'
import CompanyProfile from './CompanyProfile'
import { withRouter } from 'react-router-dom'

class Profile extends Component {
  constructor (props) {
    super(props)

    this.state = { user: this.props.user }
  }

  onModifyClick (event, history) {
    if (this.props.reloadUserData) {
      this.props.reloadUserData()
    }
    history.push('/profil/edit')
  }

  onCancelClick (event, history) {
    window.scrollTo(0, 0)
    history.push('/profil/view')
  }

  componentDidMount () {
    if (this.props.reloadUserData) {
      this.props.reloadUserData()
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.user.customer !== this.state.user.customer) {
      this.setState({ user: nextProps.user })
    }
  }

  render () {
    const history = this.props.history
    const { customer, ssoUserId } = this.state.user

    return <div className='page-center-content '>
      <div className='row my-4 align-items-center justify-content-between'>
        <div className='col'>
          <h1>Profil</h1>
        </div>
        <div className='col-auto'>
          <Button className='btn green' label='Jelszó módosítása' onClick={() => this.props.onPasswordChange()} />
        </div>
      </div>
      {this.props.match.params.mode === 'edit'
        ? (customer.type === 'individual'
          ? <IndividualProfile customer={customer}
            masterData={this.props.masterData}
            saveData={this.props.onSaveData}
            onCancelClick={(event) => this.onCancelClick(event, history)} />
          : <CompanyProfile customer={customer}
            ssoUserId={this.props.user.ssoUserId}
            masterData={this.props.masterData}
            saveData={this.props.onSaveData} />)
        : <div>
          <Button className='btn green' label='Adatok módosítása' onClick={(event) => this.onModifyClick(event, history)} />
          <StaticProfile customer={customer} ssoUserId={ssoUserId} />
        </div>
      }
      <RemarkLink />
    </div>
  }
}

Profile.propTypes = {
  user: PropTypes.object,
  masterData: PropTypes.object,
  onSaveData: PropTypes.func,
  onPasswordChange: PropTypes.func,
  reloadUserData: PropTypes.func
}

export default withRouter(Profile)
