import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { InputText } from 'primereact/components/inputtext/InputText'
import { Button } from 'primereact/components/button/Button'
import { Popup } from '../../../components/Common/UFPopup/index'

export default class LoginForm extends Component {
  constructor () {
    super()

    this.state = { username: '', password: '', newPassword1: '', newPassword2: '' }
    this.setValue = this.setValue.bind(this)
  }
  componentDidMount () {
    if (this.emailInput) {
      this.emailInput.focus()
    }
  }

  setValue (ev) {
    this.setState({ [ev.target.name]: ev.target.value })
  }

  render () {
    if (this.props.mode === 'normal' && this.props.ssoUserId) {
      return this.renderLoggedIn()
    } else {
      return this.renderNotLoggedIn()
    }
  }

  renderLoggedIn () {
    return <div>
      <div className='login-page text-left col-offset-1 col-10'>
        <h1 className='horz-sep-top50'>Bejelentkezett felhasználó</h1>
        <div className='row' />
        <div className='row' />
        <div className='text-left'>
          <div className='login-forgot-link' onClick={() => this.props.redirectToRoot()}>Folytatom a munkát</div>
        </div>
      </div>
    </div>
  }

  renderNotLoggedIn () {
    const { errorMessage } = this.props

    const title = this.props.mode === 'normal'
      ? 'Bejelentkezés'
      : this.props.mode === 'expired'
        ? 'Bejelentkezés jelszó cserével'
        : this.props.mode === 'forgot'
          ? 'Elfelejtett jelszó'
          : this.props.mode === 'change'
            ? 'Jelszó módosítás' : ''

    const buttonLabel = this.props.mode === 'normal'
      ? 'Belépés'
      : this.props.mode === 'expired'
        ? 'Belépés jelszó cserével'
        : this.props.mode === 'forgot'
          ? 'E-mail küldés'
          : this.props.mode === 'change'
            ? 'Jelszó megváltoztatás' : ''

    const content =
      <div className='login-page text-left offset-2 col-8'>
        <h1 className='horz-sep-top50'>{title}</h1>
        <div className='row'>
          <form className='col-md-5 col-sm-6 horz-sep-top20' onSubmit={(event) => this.handleClick(event)}>
            { this.props.mode !== 'change'
              ? <div className='form-group'>
                <label>E-mail cím</label>
                <input className='ui-inputtext ui-state-default ui-corner-all ui-widget form-control ui-state-filled' placeholder='E-mail cím'
                  name='username'
                  value={this.state.username}
                  onChange={this.setValue}
                  ref={(input) => { this.emailInput = input }}
                />
              </div> : null
            }
            {
              this.props.mode !== 'forgot'
                ? <div className='form-group horz-sep-top25'>
                  <label>{this.props.mode === 'expired' || this.props.mode === 'change' ? 'Régi jelszó' : 'Jelszó'}</label>
                  <InputText type='password' className='form-control' placeholder='Jelszó'
                    name='password'
                    value={this.state.password}
                    onChange={this.setValue} />
                </div>
                : <div className='form-group horz-sep-top25'>
                Ha az alábbi gombra kattint, akkor a megadott e-mail címre elküldünk egy üzenetet, amely alapján a jelszavát lecserélheti.
                </div>
            }
            {
              this.props.mode === 'expired' || this.props.mode === 'change'
                ? <div>
                  <div className='form-group horz-sep-top25'>
                    <label>Új jelszó</label>
                    <InputText type='password' className='form-control' placeholder='Új jelszó'
                      name='newPassword1'
                      value={this.state.newPassword1}
                      onChange={this.setValue} />
                  </div>
                  <div className='form-group horz-sep-top25'>
                    <label>Új jelszó megerősítése</label>
                    <InputText type='password' className='form-control' placeholder='Új jelszó megerősítése'
                      name='newPassword2'
                      value={this.state.newPassword2}
                      onChange={this.setValue} />
                  </div>
                </div> : null
            }
            <div className='form-group horz-sep-top25 horz-sep-btm25'>
              <Button label={buttonLabel} type='submit' className='btn green' onClick={(event) => this.handleClick(event)} />
            </div>
          </form>
        </div>

        {errorMessage && <p className='text-danger text-left horz-sep-btm25'>{errorMessage}</p>}

        {
          this.props.mode === 'normal'
            ? <div className='text-left'>
              <div className='login-forgot-link' onClick={() => this.props.onForgotClick()}>Elfelejtettem a jelszavamat</div>
            </div> : null
        }

        {
          this.props.mode === 'change'
            ? <div className='text-left'>
              <div className='login-forgot-link' onClick={() => this.props.redirectToRoot()}>Vissza a kezdő oldalra</div>
            </div> : null
        }

        {
          this.props.mode === 'forgot'
            ? <div className='text-left'>
              <div className='login-forgot-link' onClick={() => this.props.onLoginNormal()}>Bejelentkezés</div>
            </div> : null
        }

      </div>

    return (
      <div className='login-main row'>
        {content}
      </div>
    )
  }

  handleClick (event) {
    event.preventDefault()
    event.stopPropagation()
    const creds = {
      mode: this.props.mode,
      username: this.state.username,
      password: this.state.password,
      newPassword1: this.state.newPassword1,
      newPassword2: this.state.newPassword2,
      ssoUserId: this.props.ssoUserId
    }
    const { onLoginClick } = this.props
    onLoginClick(creds)
    // this.setState({})
  }

  handleForgotPassword (event) {
    event.preventDefault()
    event.stopPropagation()
    Popup.create({
      title: 'Elfelejtett jelszó',
      content: <div className='alert'>A(z) {this.state.username} e-mail címre elküldtünk egy üzenetet,
        amely alapján a jelszó lecserélését el tudja végezni.</div>,
      buttons: {
        center: [{
          text: 'Rendben',
          className: 'btn green',
          action: () => {
            Popup.close()
          }
        }],
        right: []
      }
    })
  }
}

LoginForm.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  onForgotClick: PropTypes.func.isRequired,
  onLoginNormal: PropTypes.func.isRequired,
  redirectToRoot: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  mode: PropTypes.string,
  ssoUserId: PropTypes.number
}
