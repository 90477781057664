/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Calendar } from 'primereact/components/calendar/Calendar'
import classNames from 'classnames'
import { helpIcon } from '../Icons'

export class UFCalendar extends Component {
  constructor (props) {
    super(props)
    this.onInputBlur = this.onInputBlur.bind(this)
    this.onInputFocus = this.onInputFocus.bind(this)
  }

  static propTypes = {
    helptext: PropTypes.string,
    helptextclassname: PropTypes.string
  }

  onInputFocus () {
    if (this.props.helptext) {
      this.helpElement.style.display = 'block'
    }
  }

  onInputBlur () {
    if (this.props.helptext) {
      this.helpElement.style.display = 'none'
    }
  }

  render () {
    return <div>
      <Calendar {...this.props} onFocus={this.onInputFocus} onBlur={this.onInputBlur} />
      {this.props.helptext
        ? <div ref={(e) => { this.helpElement = e }} className={classNames('help-message', this.props.helptextclassname)}
          style={{ display: 'none' }}>{helpIcon}
          <div className='help-text' dangerouslySetInnerHTML={{ __html: this.props.helptext }} />
        </div>
        : null}
    </div>
  }
}
export default UFCalendar
