import PropTypes from 'prop-types'
import React, { Component } from 'react'
import LinkButton from '../../../components/Common/LinkButton'
import { userName } from '../../../utils/localization'
import NotificationsContainer from '../../Notification/containers/NotificationsContainer'
import NotificationsSummary from '../../Notification/components/NotificationsSummary'

class HomeView extends Component {
  render () {
    const { buyEventCount, sellEventCount, assignmentCount } = this.props
    const buyMessage = <div>{buyEventCount === undefined
      ? '...' : (buyEventCount === 0 ? 'nincs'
        : <span className='home-badge'>{buyEventCount}</span>)} aktuális változás</div>
    const sellMessage = assignmentCount === 0 && (sellEventCount === undefined || sellEventCount < 1)
      ? <div className='greyed'>Jelenleg nincs eladó ingatlanja</div>
      : <div>{sellEventCount === undefined
        ? '...' : (sellEventCount === 0 ? 'nincs'
          : <span className='home-badge'>{sellEventCount}</span>)} aktuális változás</div>
    const title = <h1 className='page-header mt-5 mb-4'>Üdvözöljük {userName(this.props.user)}!</h1>
    return <div className='page-center-content text-center'>
      <div className='col-12'>
        {title}
        <div className='home-message'>
          Ez az Ön személyes KAPCSOLATIPONT-ja, ahol naprakészen nyomon követheti az ingatlanvásárlással
          vagy eladással kapcsolatos aktuális folyamatokat. Intézze otthonról, kényelmesen ingatlanügyeit!
        </div>
        <NotificationsContainer summary component={NotificationsSummary} />
        <div className='row mt-4 mb-4'>
          <div className='col-md-6 text-md-right text-center' >
            <div className='home-box mr-md-3 d-inline-block'>
              <div className='home-buy-logo' />
              <h3>Ingatlan vétel</h3>
              {buyMessage}
              <div><LinkButton label='Tovább' to={'/vetel/'} /></div>
            </div>
          </div>
          <div className='col-md-6 text-md-left text-center'>
            <div className='home-box ml-md-3 d-inline-block'>
              <div className='home-sell-logo' />
              <h3>Ingatlan eladás</h3>
              {sellMessage}
              <div><LinkButton label='Tovább' to={'/eladas/'} /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

HomeView.propTypes = {
  user     : PropTypes.object,
  buyEventCount : PropTypes.number,
  sellEventCount : PropTypes.number,
  assignmentCount : PropTypes.number
}

export default HomeView
