/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import { connect } from 'react-redux'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { fetchEvents, selectEvents, selectEventFlags } from '../../../store/events'
import fetchOnUpdate from '../../../utils/fetchOnUpdate'
import { UserIsAuthenticated } from '../../index'
import Notifications from '../components/Notifications'
import NotificationsSummary from '../components/NotificationsSummary'

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: { onFetchEvents: () => dispatch(fetchEvents('notification', undefined, 'none')) }
})

const mapStateToProps = (state, ownProps) => {
  const flags = selectEventFlags(state, 'notification', undefined, 'none')
  const events = selectEvents(state, 'notification', undefined, 'none')
  return {
    events: events,
    isLoading: flags.isLoading,
    isLoaded: flags.isLoaded,
    hasError: flags.hasError,
    error: flags.error
  }
}

const NotificationsOrSummary = ({summary, ...props}) =>
  summary ? <NotificationsSummary {...props} /> : <Notifications {...props} />

/**
 * Kétféle kinézetet tud felvenni a konténer, amelyet a summary true/false értékkel lehet szabályozni
 * - egy összefoglaló, ami a nyitó oldalon jelenik meg
 * - egy részletes lita, amelynek külön URL-je van
 * TODO: a summary esetén nem kellene a backendtől elkérni az összes értesítést, elég lenne csak, aminek meg kell jelenni
 */
export default UserIsAuthenticated(
    connect(mapStateToProps, mapDispatchToProps)(
      fetchOnUpdate([], (params, actions) => { actions.onFetchEvents() })
      (NotificationsOrSummary)
    )
)
