/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

'use strict'

import React, { Component } from 'react'
import ActionButton from './ActionButton'
import PropTypes from 'prop-types'

const buttonSpaceDefaultProps = {
  buttons: null,
  className: null,
  onOk: null,
  onClose: null,
  buttonClick: null,
  btnClass: null,
  href: null
}

class ButtonsSpace extends Component {
  onOk () {
    if (this.props.onOk) {
      return this.props.onOk()
    }
  }

  onClose () {
    if (this.props.onClose) {
      return this.props.onClose()
    }
  }

  buttonClick (action) {
    if (this.props.buttonClick) {
      return this.props.buttonClick(action)
    }
  }

  wildClass (className) {
    if (!className) {
      return null
    }

    if (this.props.wildClasses) {
      return className
    }

    let finalClass = []
    let classNames = className.split(' ')

    classNames.forEach(function (className) {
      finalClass.push(className)
    })

    return finalClass.join(' ')
  }

  render () {
    if (!this.props.buttons) {
      return null
    }

    let btns = []

    for (let i in this.props.buttons) {
      if (this.props.buttons.hasOwnProperty(i)) {
        let btn = this.props.buttons[i]
        let url = (btn.url) ? btn.url : null

        if (typeof btn === 'string') {
          if (btn === 'ok') {
            btns.push(<ActionButton className={this.props.btnClass + ' ' + this.props.btnClass + '--ok'} key={i}
              onClick={() => this.onOk()}>{this.props.defaultOk}</ActionButton>)
          } else if (btn === 'cancel') {
            btns.push(<ActionButton className={this.props.btnClass + ' ' + this.props.btnClass + '--cancel'} key={i}
              onClick={() => this.onClose()}>{this.props.defaultCancel}</ActionButton>)
          }
        } else {
          let className = this.props.btnClass + ' ' + this.wildClass(btn.className)
          btns.push(<ActionButton className={className} key={i} url={url} onClick={() => this.buttonClick(btn.action)}>{btn.text}</ActionButton>)
        }
      }
    }

    return (
      <div className={this.props.className}>
        {btns}
      </div>
    )
  }
}

ButtonsSpace.displayName = 'PopupFooterButtons'
ButtonsSpace.defaultProps = buttonSpaceDefaultProps

ButtonsSpace.propTypes = {
  wildClasses: PropTypes.bool,
  btnClass: PropTypes.string,
  className: PropTypes.string,
  defaultOk: PropTypes.string,
  defaultCancel: PropTypes.string,
  buttons: PropTypes.array,
  onOk: PropTypes.func,
  onClose: PropTypes.func,
  buttonClick: PropTypes.func
}

const defaultProps = {
  buttons: null,
  className: null,
  wildClasses: false,
  btnClass: null,
  defaultOk: null,
  defaultCancel: null,
  buttonClick: null,
  onOk: null,
  onClose: null
}

class Footer extends Component {
  render () {
    if (this.props.buttons) {
      return (
        <footer className={this.props.className}>
          <ButtonsSpace
            buttonClick={this.props.buttonClick}
            onOk={this.props.onOk}
            onClose={this.props.onClose}
            className={this.props.className + '__left-space'}
            wildClasses={this.props.wildClasses}
            btnClass={this.props.btnClass}
            defaultOk={this.props.defaultOk}
            defaultCancel={this.props.defaultCancel}
            buttons={this.props.buttons.left} />

          <ButtonsSpace
            buttonClick={this.props.buttonClick}
            onOk={this.props.onOk}
            onClose={this.props.onClose}
            className={this.props.className + '__center-space'}
            wildClasses={this.props.wildClasses}
            btnClass={this.props.btnClass}
            defaultOk={this.props.defaultOk}
            defaultCancel={this.props.defaultCancel}
            buttons={this.props.buttons.center} />

          <ButtonsSpace
            buttonClick={this.props.buttonClick}
            onOk={this.props.onOk}
            onClose={this.props.onClose}
            className={this.props.className + '__right-space'}
            wildClasses={this.props.wildClasses}
            btnClass={this.props.btnClass}
            defaultOk={this.props.defaultOk}
            defaultCancel={this.props.defaultCancel}
            buttons={this.props.buttons.right} />
        </footer>
      )
    }
    return null
  }
}

Footer.propTypes = {
  wildClasses: PropTypes.bool,
  btnClass: PropTypes.string,
  className: PropTypes.string,
  defaultOk: PropTypes.string,
  defaultCancel: PropTypes.string,
  buttons: PropTypes.object,
  onOk: PropTypes.func,
  onClose: PropTypes.func,
  buttonClick: PropTypes.func
}

Footer.displayName = 'PopupFooter'
Footer.defaultProps = defaultProps

export default Footer
