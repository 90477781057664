/*
 * Copyright 2020 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React from 'react'
import { formatDateTime } from '../../utils/localization'

const SimpleEvent = ({ event }) => (
	<>
		<div dangerouslySetInnerHTML={{ __html: event.eventMessage }} />
		<div><i>{formatDateTime(event.created)}</i> / azonosító: <b>{event.processId}</b></div>
		<div>
			{ event.deadline ? <span><b>Lejárat dátuma: {formatDateTime(event.deadline)}</b></span> : null }
		</div>
	</>
)

export default SimpleEvent
