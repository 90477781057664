/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React from 'react'

import LinkButton from '../Common/LinkButton'

export const RemarkLink = (props) => (
  <div className='remark-form grey-box py-4 '>
    <div className='container-fluid'>
      <div className='page-center-content px-5'>
        <h3><strong>Észrevétele van?</strong></h3>
        <p>
          Írja meg nekünk véleményét! Várjuk észrevételeit, legyen szó megbízásról,
          ingatlan bemutatásról, vételről vagy bármely általános,
          az OTP Ingatlanpontot érintő kérdésről.
        </p>
        <LinkButton label='Vélemény írása' to={'/velemeny/' + encodeURIComponent(window.location.href)} />
      </div>
    </div>
  </div>
)

export default RemarkLink
