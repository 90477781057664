import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { exclamationIcon } from '../components/Common/Icons'
import { Button } from 'primereact/components/button/Button'
import { doLogin, setGlobalOtpipAuth } from '../routes/Login/modules/login'
import Cookies from 'universal-cookie'
import CoreLayout from '../layouts/CoreLayout/CoreLayout'
import LoginContainer from '../routes/Login/containers/LoginContainer'
import HomeViewContainer from '../routes/Home/containers/HomeViewContainer'
import ProfileContainer from '../routes/Profile/containers/ProfileContainer'
import PasswordChangeContainer from '../routes/Login/containers/PasswordChangeContainer'
import NotificationsContainer from '../routes/Notification/containers/NotificationsContainer'
import RemarkContainer from '../routes/Remark/containers/RemarkContainer'
import { UserIsAuthenticated } from '../routes'

const AssignmentRoutes = React.lazy(() => import('../routes/Assignment'))
const BuyRoutes = React.lazy(() => import('../routes/Buy'))

const cookies = new Cookies()

// ---------- Időzített adat frissítés az SSO bejelentkezéshez --------------
let dataRefresh

const dataRefreshFunction = (dispatch) => () => {
  // eslint-disable-next-line no-undef
  fetch(API_URL_JS + '/dataRefresh', { method: 'GET', credentials: 'include' })
    .then(response => {
      if (response.ok) {
        console.log('Sikeres dataRefresh: ' + new Date())
        // dataRefresh után megváltozik az otpipAuth cookie, ezt el kell menteni, különben a következő
        // IsUserAuthenticated hívás hívna egy doLogint, ami miatt újra hívódna a dataReferesh
        const otpipAuth = cookies.get(COOKIE_NAME) // eslint-disable-line no-undef
        setGlobalOtpipAuth(otpipAuth)
        // a felhasználó adatait frissítjük a szerverről
        doLogin(dispatch)
      }
    })
    .catch(err => {
      console.log('Hiba a dataRefresh-ben: ', err)
    })
  // eslint-disable-next-line no-undef
}

const startDataRefresh = (dispatch) => {
  if (dataRefresh) {
    clearInterval(dataRefresh)
  }
  // eslint-disable-next-line no-undef
  dataRefresh = setInterval(dataRefreshFunction(dispatch), DATA_REFRESH_MINS * 60000)
  dataRefreshFunction(dispatch)()
}

const stopDataRefresh = () => {
  if (dataRefresh) {
    clearInterval(dataRefresh)
    dataRefresh = undefined
  }
}
// --------------------------------------------------------------------------

class AppContainer extends Component {
  static propTypes = {
    store  : PropTypes.object.isRequired,
    history : PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)
    this.state = { settingsLoaded: false }
    this.reloadPage = this.reloadPage.bind(this)
  }

  doFetch () {
    console.log('API_URL_JS: ' + API_URL_JS) // eslint-disable-line no-undef
    // eslint-disable-next-line no-undef
    fetch(API_URL_JS + '/client-settings')
      .then(res => {
        res.text().then(text => {
          const json = JSON.parse(text)
          Object.keys(json).forEach((key) => {
            window[key] = json[key]
          })
          console.log('kapcsolatIPont ' + __VERSION__ + ' elindult.') // eslint-disable-line no-undef
          console.log('API_URL_JS: ' + API_URL_JS) // eslint-disable-line no-undef
          console.log('SERVER_VERSION: ' + SERVER_VERSION) // eslint-disable-line no-undef
          console.log('COOKIE_NAME: ' + COOKIE_NAME) // eslint-disable-line no-undef
          console.log('COOKIE_DOMAIN: ' + COOKIE_DOMAIN) // eslint-disable-line no-undef
          console.log('DISTRIB_TPYE: ' + DISTRIB_TYPE) // eslint-disable-line no-undef
          console.log('process.env.NODE_ENV: ' + process.env.NODE_ENV)
          this.setState({ settingsLoaded: true })
        })
      })
      .catch(err => {
        this.setState({ settingsError: true, errorObject: err })
      })
  }

  componentDidMount () {
    this.doFetch()
  }

  componentDidUpdate (prevProps, prevState) {
    const state = this.props.store.getState()
    // if (!state.login || !state.login.isAuthenticated) {
    //  doLogin(this.props.store.dispatch).then(() => {
    //    startDataRefresh(this.props.store.dispatch)
    const store = this.props.store
    let currentlyAuthenticated = state.login && state.login.isAuthenticated
    store.subscribe(() => {
      const state = store.getState()
      if (state.login && !state.login.isAuthenticated && currentlyAuthenticated) {
        stopDataRefresh()
        currentlyAuthenticated = state.login.isAuthenticated
      } else if (state.login && state.login.isAuthenticated && !currentlyAuthenticated) {
        startDataRefresh(this.props.store.dispatch)
        currentlyAuthenticated = state.login.isAuthenticated
      }
    })
    //      })
    //    } else if (state.login && state.login.isAuthenticated) {
    //      startDataRefresh(this.props.store.dispatch)
    //    }
  }

  reloadPage () {
    this.setState({ settingsLoaded: false, settingsError: false })
    this.doFetch()
  }

  render () {
    const { store, history } = this.props

    return (
      this.state.settingsLoaded
        ? <Provider store={store}>
          <div style={{ height: '100%' }}>
            <ConnectedRouter history={history}>
              <Route path='/'>
                <CoreLayout>
                    <React.Suspense fallback={<div><span className='fa fa-spinner fa-pulse fa-4x fa-fw' /></div>}>
                        <AssignmentRoutes />
                        <BuyRoutes />
                        <Route path='/velemeny/:url' component={UserIsAuthenticated(RemarkContainer)} />
                        <Route path='/login' component={LoginContainer} />
                        <Route path='/jelszo-csere' component={PasswordChangeContainer} />
                        <Route path='/ertesitesek' component={NotificationsContainer} />
                        <Route path='/profil/:mode' component={ProfileContainer} />
                        <Route exact path='/' component={HomeViewContainer} />
                    </React.Suspense>
                </CoreLayout>
              </Route>
            </ConnectedRouter>
          </div>
        </Provider>
        : <div className='row'>
          <div className='col-12'> { this.state.settingsError
            ? <div className='home-box mt-4'>
              <div>{exclamationIcon}</div>
              <h3>A kiszolgáló nem elérhető</h3>
              <p className='home-message'>{this.state.errorObject.message}</p>
              <br />
              <div><Button label='Újratöltés' onClick={this.reloadPage} /></div>
            </div>
            : <div className='home-message'>
              <span className='fa fa-spinner fa-pulse fa-4x fa-fw' />
            </div>
          }
          </div>
        </div>
    )
  }
}

export default AppContainer
