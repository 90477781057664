/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { form, from } from 'react-inform'
import { Button } from 'primereact/components/button/Button'
import { InputText } from '../../../components/Common/UFPrimeReact/UFInputText'
import { warningIcon } from '../../../components/Common/Icons'
import Address from '../../../components/Common/Address'
import Phone from '../../../components/Common/Phone'
import { iHelp, phoneCountryCode, phoneNumber } from '../../../utils/commonFunctions'
import { PhoneListEditor } from './PhoneListEditor'

const exists = v => Boolean(v)

let originalPhone = null
let originalEmail = null

const validatePhoneAndEmail = (email, phone) => {
  return !(JSON.stringify(originalPhone) !== JSON.stringify(phone) && originalEmail !== email)
}

const validatePhone = (value) => {
  return value ? (value.countryCode && value.phoneNumber) : true
}

const validatePhoneCountry = (value) => {
  return value && value.countryCode ? phoneCountryCode.test(value.countryCode) : true
}
const validatePhoneNumber = (value) => {
  return value && value.phoneNumber ? phoneNumber.test(value.phoneNumber) : true
}
const validatePhoneCountryInArray = (value) => {
  if (value) {
    let count = 0
    value.map(v => {
      if (!validatePhoneCountry(v)) {
        count++
      }
    })
    return count === 0
  } else {
    return true
  }
}
const validatePhoneNumberInArray = (value) => {
  if (value) {
    let count = 0
    value.map(v => {
      if (!validatePhoneNumber(v)) {
        count++
      }
    })
    return count === 0
  } else {
    return true
  }
}

const rulesMap = {
  phone: {
    'Kérjük töltse ki a mezőt!': validatePhone,
    'A megadott országkód nem megfelelő!': validatePhoneCountry,
    'A telefonszám nem megfelelő, tartalmaz nem megengedett karaktereket vagy túl rövid vagy túl hosszú!' : validatePhoneNumber
  },
  newPhone: {
    'A megadott országkód nem megfelelő!': validatePhoneCountry,
    'A telefonszám nem megfelelő, tartalmaz nem megengedett karaktereket vagy túl rövid vagy túl hosszú!' : validatePhoneNumber
  },
  contactPhone: {
    'A megadott országkód nem megfelelő!': validatePhoneCountry,
    'A telefonszám nem megfelelő, tartalmaz nem megengedett karaktereket vagy túl rövid vagy túl hosszú!' : validatePhoneNumber
  },
  phones: {
    'A megadott országkód nem megfelelő!': validatePhoneCountryInArray,
    'A telefonszám nem megfelelő, tartalmaz nem megengedett karaktereket vagy túl rövid vagy túl hosszú!' : validatePhoneNumberInArray
  },
  email: {
    'Kérjük töltse ki a mezőt!': exists,
    'Nem megengedett az e-mail cím és az kapcsolatIPont telefonszám együttes módosítása!': (email, values) => validatePhoneAndEmail(email, values.phone),
    'Az email cím formátuma nem megfelelő!': (email) =>
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(email)
  }
}
class CompanyProfile extends Component {
  constructor (props) {
    super(props)

    const { customer } = this.props
    const actualCompanyInfo = customer.companyInfo.filter(e => this.props.ssoUserId === e.ssoUserId)[0]
    originalEmail = actualCompanyInfo.email
    originalPhone = { ...actualCompanyInfo.phone, countryCode: '+' + actualCompanyInfo.phone.countryCode }

    let tempPhones = customer.phones.slice()
    for (let i in tempPhones) {
      tempPhones[i] = { ...tempPhones[i], countryCode: '+' + tempPhones[i].countryCode }
    }

    this.state = {
      warningMessage: '',
      newPhone: '',
      newPhoneError: '',
      companyInfo: {
        notificationAddress: actualCompanyInfo.notificationAddress,
        email: actualCompanyInfo.email,
        phone: { ...actualCompanyInfo.phone, countryCode: '+' + actualCompanyInfo.phone.countryCode }
      },
      customer: {
        contact: customer.contact ? customer.contact : '',
        contactEmail: customer.contactEmail ? customer.contactEmail : '',
        contactPhone: { ...customer.contactPhone, countryCode: '+' + (customer.contactPhone ? customer.contactPhone.countryCode : '') },
        phones: tempPhones,
        postboxAddress: customer.postboxAddress ? customer.postboxAddress : ''
      }
    }

    this.setCustomerValue = this.setCustomerValue.bind(this)
    this.setCompanyInfoEmailValue = this.setCompanyInfoEmailValue.bind(this)
    this.onAddPhoneClick = this.onAddPhoneClick.bind(this)
    this.onDeletePhoneClick = this.onDeletePhoneClick.bind(this)
    this.updatePhone = this.updatePhone.bind(this)
    this.updateNotificationAddress = this.updateNotificationAddress.bind(this)
  }

  componentWillMount () {
    this.setValuesToValidation()
  }

  componentWillUpdate () {
    const { form } = this.props
    if (this.state.warningMessage !== '' && form.isValid()) {
      this.setState({ warningMessage: '' })
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.customer !== this.props.customer) {
      const { customer } = nextProps
      const actualCompanyInfo = customer.companyInfo.filter(e => nextProps.ssoUserId === e.ssoUserId)[0]
      originalEmail = actualCompanyInfo.email
      originalPhone = actualCompanyInfo.phone

      let tempPhones = customer.phones.slice()
      for (let i in tempPhones) {
        tempPhones[i] = { ...tempPhones[i], countryCode: '+' + tempPhones[i].countryCode }
      }

      this.setState({
        companyInfo: {
          notificationAddress: actualCompanyInfo.notificationAddress,
          email: actualCompanyInfo.email,
          phone: { ...actualCompanyInfo.phone, countryCode: '+' + actualCompanyInfo.phone.countryCode }
        },
        customer: {
          contact: customer.contact ? customer.contact : '',
          contactEmail: customer.contactEmail ? customer.contactEmail : '',
          contactPhone: { ...customer.contactPhone, countryCode: '+' + (customer.contactPhone ? customer.contactPhone.countryCode : '') },
          phones: tempPhones,
          postboxAddress: customer.postboxAddress ? customer.postboxAddress : ''
        }
      })
    }
  }

  setValuesToValidation (updateableFields) {
    const { form } = this.props
    form.onValues({ 'phone': this.state.companyInfo.phone,
      'email': this.state.companyInfo.email,
      'contactPhone' : this.state.customer.contactPhone,
      'phones' : this.state.customer.phones
    })
  }

  setCustomerValue (ev) {
    this.setState({ customer: { ...this.state.customer, [ev.target.name]: ev.target.value } })
  }

  setCompanyInfoEmailValue (ev) {
    this.setState({ companyInfo: { ...this.state.companyInfo, [ev.target.name]: ev.target.value } })
    const { email } = this.props.fields
    email.props.onChange(ev)
  }

  onAddPhoneClick (event) {
    event.preventDefault()
    if (this.state.newPhone.countryCode && this.state.newPhone.phoneNumber &&
      validatePhoneCountry(this.state.newPhone) && validatePhoneNumber(this.state.newPhone)) {
      this.setState({
        customer: {
          ...this.state.customer,
          phones: this.state.customer.phones.concat([this.state.newPhone])
        }
      })
      this.setState({ newPhone: { ...this.state.newPhone, countryCode: null, phoneNumber: '' } })
      this.setState({ newPhoneError: '' })
    } else {
      this.setState({ newPhoneError: 'Kérem adja meg a helyes telefonszámot!' })
    }
  }

  onDeletePhoneClick (event, index) {
    event.preventDefault()
    const newPhones = this.state.customer.phones.slice()
    newPhones.splice(index, 1)
    this.setState({ customer: { ...this.state.customer, phones: newPhones } })
  }

  updatePhone (name, fieldName, fieldValue) {
    if (name.startsWith('phone')) {
      let i = name.slice(-1)
      let tempPhones = this.state.customer.phones.slice()
      tempPhones[i] = { ...tempPhones[i], [fieldName]: fieldValue }
      this.setState({ customer: { ...this.state.customer, phones: tempPhones } })
      const { phones } = this.props.fields
      phones.props.onChange(tempPhones)
    } else {
      let newPhoneValue
      switch (name) {
        case 'newPhone':
          newPhoneValue = { ...this.state.newPhone, [fieldName]: fieldValue }
          this.setState({ newPhone : newPhoneValue })
          const { newPhone } = this.props.fields
          newPhone.props.onChange(newPhoneValue)
          break
        case 'customer.contactPhone':
          newPhoneValue = { ...this.state.customer.contactPhone, [fieldName]: fieldValue }
          this.setState({ customer: { ...this.state.customer, contactPhone: newPhoneValue } })
          const { contactPhone } = this.props.fields
          contactPhone.props.onChange(newPhoneValue)
          break
        case 'companyInfo.phone':
          newPhoneValue = { ...this.state.companyInfo.phone, [fieldName]: fieldValue }
          this.setState({ companyInfo: { ...this.state.companyInfo, phone: newPhoneValue } })
          const { phone } = this.props.fields
          phone.props.onChange(newPhoneValue)
          break
      }
    }
  }

  updateNotificationAddress (newAddress) {
    this.setState({ companyInfo: { ...this.state.companyInfo, notificationAddress: newAddress } })
  }

  onSendClick (event) {
    event.stopPropagation()
    event.preventDefault()

    const { form } = this.props
    form.forceValidate()
    if (form.isValid()) {
      if (this.state.newPhone.phoneNumber && this.state.newPhone.phoneNumber.length > 0) {
        this.setState({ warningMessage: 'Új telefonszámot adott meg, de nem használta a Hozzáad gombot!' })
      } else {
        this.setState({ warningMessage: '' })
        const { customer } = this.props
        let customerCopy = JSON.parse(JSON.stringify(customer))

        const updatedCompanyInfo = { ...customerCopy.companyInfo.filter(e => this.props.ssoUserId === e.ssoUserId)[0], ...this.state.companyInfo }
        customerCopy.companyInfo[customerCopy.companyInfo.findIndex(e => this.props.ssoUserId === e.ssoUserId)] = updatedCompanyInfo

        const customerFormData = new FormData()

        const additionalData = { detailsDataModified: false }

        customerFormData.append('customerWithAdditionalData',
          new Blob([JSON.stringify({ ...customerCopy, ...this.state.customer, additionalData })], {
            type: 'application/json'
          }))

        this.props.saveData(customerFormData)
        window.scrollTo(0,0)
      }
    } else {
      this.setState({ warningMessage: 'Kérem ellenőrizze a megadott adatok helyességét!' })
    }
  }

  render () {
    const { email, phone, phones, newPhone, contactPhone } = this.props.fields
    const { customer } = this.props
    const actualCompanyInfo = customer.companyInfo.filter(e => this.props.ssoUserId === e.ssoUserId)[0]

    const privateData = <div className='box-body row'>
      <div className='col'>
        <div className='secondary-header'>Személyes adatok</div>
        <div className='form-row'>
          <div className='form-group col-12'>
            <label className='text-uppercase'>E-mail cím<span className='redStar'>*</span></label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <InputText className='form-control'
              placeholder='E-mail cím'
              name='email'
              value={this.state.companyInfo.email}
              onChange={this.setCompanyInfoEmailValue}
              helptext={iHelp(this.props.masterData, 'uf_ugyfelemail')}
              helptextclassname='help-message-right'
            />
            <div className={email.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
              <span className='warning-text'>{email.error}</span></div>
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group col-12'>
            <label className='text-uppercase'>kapcsolatIPont telefonszám<span className='redStar'>*</span></label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <div className='row'>
              <div className='col-7'>
                <Phone name='companyInfo.phone'
                  countryCode={this.state.companyInfo.phone ? this.state.companyInfo.phone.countryCode : ''}
                  phoneNumber={this.state.companyInfo.phone ? this.state.companyInfo.phone.phoneNumber : ''}
                  masterData={this.props.masterData}
                  updatePhone={this.updatePhone}
                  helpkey='uf_ugyfelfioktelefonszam'
                  helptextclassname='help-message-right'
                />
              </div>
            </div>
            <div className={phone.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
              <span className='warning-text'>{phone.error}</span></div>
          </div>
        </div>
        <div className='row'>
          <div className='form-group col-md-12'>
            <label className='text-uppercase'>Tartózkodási hely/értesítési cím</label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <Address address={actualCompanyInfo.notificationAddress}
              masterData={this.props.masterData}
              updateAddress={this.updateNotificationAddress}
              helpkey='uf_ugyfelertesitesicim'
              helptextclassname='help-message-right'
            />
          </div>
        </div>
      </div>
    </div>

    const companyContactBlock = <div className='box-body row'>
      <div className='col-12'>
        <div className='form-row'>
          <div className='form-group col'>
            <label className='text-uppercase'>Céges kapcsolattartó neve</label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <InputText className='form-control'
              placeholder='Céges kapcsolattartó neve'
              name='contact'
              value={this.state.customer.contact}
              onChange={this.setCustomerValue}
              helptext={iHelp(this.props.masterData, 'uf_ugyfelkapcsnev')}
              helptextclassname='help-message-right'
            />
          </div>
        </div>
        <div className='row'>
          <div className='form-group col-md-12'>
            <label className='text-uppercase'>Céges kapcsolattartó e-mail címe</label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <InputText className='form-control'
              placeholder='Céges kapcsolattartó e-mail címe'
              name='contactEmail'
              value={this.state.customer.contactEmail}
              onChange={this.setCustomerValue}
              helptext={iHelp(this.props.masterData, 'uf_ugyfelkapcsemail')}
              helptextclassname='help-message-right'
            />
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group col-12'>
            <label className='text-uppercase'>Céges kapcsolattartó telefonszáma</label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <div className='row'>
              <div className='col-7'>
                <Phone name='customer.contactPhone'
                  countryCode={this.state.customer.contactPhone ? this.state.customer.contactPhone.countryCode : ''}
                  phoneNumber={this.state.customer.contactPhone ? this.state.customer.contactPhone.phoneNumber : ''}
                  masterData={this.props.masterData}
                  updatePhone={this.updatePhone}
                  helpkey='uf_ugyfelkapcstelszam'
                  helptextclassname='help-message-right'
                />
              </div>
            </div>
            <div className={contactPhone.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
              <span className='warning-text'>{contactPhone.error}</span></div>
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group col-md-12'>
            <label className='text-uppercase'>Postafiók cím</label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <InputText className='form-control'
              placeholder='Postafiók cím'
              name='postboxAddress'
              value={this.state.customer.postboxAddress}
              onChange={this.setCustomerValue}
              helptext={iHelp(this.props.masterData, 'uf_ugyfelpostafiokcim')}
              helptextclassname='help-message-right'
            />
          </div>
        </div>
      </div>
      <div className='col-12'>
        <div className='form-row'>
          <div className='form-group col-md-12'>
            <label className='text-uppercase'>További telefonszámok</label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            {this.state.customer.phones ? this.state.customer.phones.map((phone, index) => {
              return <PhoneListEditor key={'phonediv' + (index)} index={index} customer={this.state.customer}
                masterData={this.props.masterData} updatePhone={this.updatePhone}
                onClick={(e) => this.onDeletePhoneClick(e, index)} />
            }) : null}
            <div className={phones.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
              <span className='warning-text'>{phones.error}</span></div>
            {this.state.customer.phones && this.state.customer.phones.length < 5
              ? <div>
                <div className='phone form-row align-items-center'>
                  <div className='col-7'>
                    <Phone name='newPhone'
                      countryCode={this.state.newPhone.countryCode}
                      phoneNumber={this.state.newPhone.phoneNumber}
                      masterData={this.props.masterData}
                      updatePhone={this.updatePhone}
                      helpkey='uf_ugyfeltovabbitelszam'
                      helptextclassname='help-message-top'
                    />
                  </div>
                  <div className='col-2'>
                    <Button className='btn green' label='Hozzáad' onClick={this.onAddPhoneClick} />
                  </div>
                </div>
                <div className={newPhone.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
                  <span className='warning-text'>{newPhone.error}</span></div>
                <div className={this.state.newPhoneError ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
                  <span className='warning-text'>{this.state.newPhoneError}</span></div>
              </div>
              : null}
          </div>
        </div>
      </div>
    </div>

    return <div className='profile'>
      <form onSubmit={event => this.onSendClick(event)}>
        <div className='profile-box container-fluid'>
          <div className='primary-header row'>Kapcsolattartási adatok módosítása</div>
          { companyContactBlock }
          { privateData }
        </div>
        <div className={this.state.warningMessage ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
          <span className='warning-text'>{this.state.warningMessage}</span></div>
        <div className='button'>
          <Button className='btn green' label='Módosítás' onClick={(event) => this.onSendClick(event)} />
        </div>
      </form>
    </div>
  }
}

CompanyProfile.propTypes = {
  customer: PropTypes.object.isRequired,
  saveData: PropTypes.func.isRequired,
  masterData: PropTypes.object,
  ssoUserId: PropTypes.number,
  form: PropTypes.object,
  fields: PropTypes.array
}

CompanyProfile = form(from(rulesMap))(CompanyProfile)
export default CompanyProfile
