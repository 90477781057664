/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'primereact/components/button/Button'
import { formatDate, humanFileSize, mimeTypeToText } from '../../utils/localization'

var mimetype2fa = require('mimetype-to-fontawesome')({ prefix: 'fa-' })

/**
 * Fájlletöltésekhez használható elem.
 */
/* eslint-disable no-undef */
export class DownloadButton extends Component {
  render () {
    const { mimeType, title, fileDate, fileSize } = this.props
    return (
      <div className='downloadButton-box '>
        <div className={'icon valign fa fa-2x ' + `${mimetype2fa(mimeType)}`} />
        <div className='title-box'>
          <span className='title'>{title}</span><br />
          { fileDate ? <span className='fileDate'>{formatDate(fileDate)}</span> : null }<br />
          <span className='fileProperties'>{mimeTypeToText(mimeType)}{fileSize && fileSize !== 1 ? ', ' + humanFileSize(fileSize) : ''}</span>
        </div>
        <div>
          <a className='h-100 d-block' href={`${API_URL_JS}/document/${this.props.documentId}`} target='_blank'>
            <Button className='arrow-button h-100 d-block' icon='fa-arrow-down' />
          </a>
        </div>
      </div>
    )
  }
}

DownloadButton.propTypes = {
  mimeType: PropTypes.string,
  title: PropTypes.string,
  documentId: PropTypes.string,
  fileDate: PropTypes.string,
  fileSize: PropTypes.number
}

/* eslint-disable no-undef */
export class DownloadSampleDocButton extends Component {
  render () {
    const { mimeType, title, fileDate, fileSize } = this.props
    return (
      <div className='downloadButton-box'>
        <div className={'icon valign fa fa-2x ' + `${mimetype2fa(mimeType)}`} />
        <div className='title-box'>
          <span className='title'>{title}</span><br />
          { fileDate ? <span className='fileDate'>{formatDate(fileDate)}</span> : null }<br />
          <span className='fileProperties'>{mimeTypeToText(mimeType)}{fileSize ? ', ' + humanFileSize(fileSize) : ''}</span>
        </div>
        <a href={`${API_URL_JS}/sampleDocument${this.props.queryParam}`} target='_blank'><Button className='arrow-button' icon='fa-arrow-down' /></a>
      </div>
    )
  }
}

DownloadSampleDocButton.propTypes = {
  mimeType: PropTypes.string,
  title: PropTypes.string,
  queryParam: PropTypes.string,
  fileDate: PropTypes.string,
  fileSize: PropTypes.number
}

export default DownloadButton
