/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import { createFetchAction } from './fetchReducer'

export const fetchEntity = (entityType, entityId) => (
  createFetchAction(
    {
      endPoint: `${entityType}s/${entityId}`,
      statePath: `${entityType}sById`,
      subPath: entityId
    })
)

// ------------------------------
// Selectors
// ------------------------------

export const getAgentById = (state, id) => {
  return getEntity(state, 'agent', id)
}

export const getBuyOfferById = (state, id) => {
  return getEntity(state, 'buyOffer', id)
}

export const getAssignmentById = (state, id) => {
  return getEntity(state, 'assignment', id)
}

export const getPresentationById = (state, id) => {
  return getEntity(state, 'presentation', id)
}

export const getDocumentById = (state, id) => {
  return getEntity(state, 'document', id)
}

export const getCustomerById = (state, id) => {
  return getEntity(state, 'customer', id)
}

export const getOfficeById = (state, id) => {
  return getEntity(state, 'office', id)
}

export const getMarketingFragmentById = (state, id) => {
  return getEntity(state, 'marketingFragment', id)
}

export const getSubProcessById = (state, id) => {
  return getEntity(state, 'subProcesse', id)
}

export const getEntity = (state, entityType, id) => {
  if (state.fetch[entityType + 'sById'] && state.fetch[entityType + 'sById'][id]) {
    const flags = state.fetch[entityType + 'sById'][id]
    return { ...flags.fetched,
      isFetchNeeded: false,
      isLoaded: flags.isLoaded,
      isLoading: flags.isLoading,
      hasError: flags.hasError,
      error: flags.error,
      isRefetchNeeded: flags.isRefetchNeeded }
  } else {
    return { isFetchNeeded: true, isLoading: true, isLoaded: false, hasError: false }
  }
}
