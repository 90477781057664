/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

export default class DomHandler {
  static innerWidth (el) {
    let width = el.offsetWidth
    let style = getComputedStyle(el)

    width += parseFloat(style.paddingLeft) + parseFloat(style.paddingRight)
    return width
  }

  static width (el) {
    let width = el.offsetWidth
    let style = getComputedStyle(el)

    width -= parseFloat(style.paddingLeft) + parseFloat(style.paddingRight)
    return width
  }

  static getWindowScrollTop () {
    let doc = document.documentElement
    return (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
  }

  static getWindowScrollLeft () {
    let doc = document.documentElement
    return (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0)
  }

  static getOuterWidth (el, margin) {
    let width = el.offsetWidth

    if (margin) {
      let style = getComputedStyle(el)
      width += parseFloat(style.marginLeft) + parseFloat(style.marginRight)
    }

    return width
  }

  static getOuterHeight (el, margin) {
    let height = el.offsetHeight

    if (margin) {
      let style = getComputedStyle(el)
      height += parseFloat(style.marginTop) + parseFloat(style.marginBottom)
    }

    return height
  }

  static getViewport () {
    let win = window
    let d = document
    let e = d.documentElement
    let g = d.getElementsByTagName('body')[0]
    let w = win.innerWidth || e.clientWidth || g.clientWidth
    let h = win.innerHeight || e.clientHeight || g.clientHeight

    return { width: w, height: h }
  }

  static getOffset (el) {
    const rect = el.getBoundingClientRect()

    return {
      top: rect.top + document.body.scrollTop,
      left: rect.left + document.body.scrollLeft
    }
  }

  static getZindex () {
    this.zindex = this.zindex || 1000
    return this.zindex++
  }

  static addMultipleClasses (element, className) {
    if (element.classList) {
      let styles = className.split(' ')
      for (let i = 0; i < styles.length; i++) {
        element.classList.add(styles[i])
      }
    } else {
      let styles = className.split(' ')
      for (let i = 0; i < styles.length; i++) {
        element.className += ' ' + styles[i]
      }
    }
  }

  static addClass (element, className) {
    if (element.classList) { element.classList.add(className) } else { element.className += ' ' + className }
  }

  static removeClass (element, className) {
    if (element.classList) {
      element.classList.remove(className)
    } else {
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
    }
  }

  static hasClass (element, className) {
    if (element.classList) {
      return element.classList.contains(className)
    } else {
      return new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className)
    }
  }

  static find (element, selector) {
    return element.querySelectorAll(selector)
  }

  static findSingle (element, selector) {
    return element.querySelector(selector)
  }

  static getHeight (el) {
    let height = el.offsetHeight
    let style = getComputedStyle(el)

    height -= parseFloat(style.paddingTop) + parseFloat(style.paddingBottom) + parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth)

    return height
  }

  static getWidth (el) {
    let width = el.offsetWidth
    let style = getComputedStyle(el)

    width -= parseFloat(style.paddingLeft) + parseFloat(style.paddingRight) + parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth)

    return width
  }

  static absolutePosition (element, target) {
    let elementDimensions = element.offsetParent
      ? { width: element.offsetWidth, height: element.offsetHeight }
      : this.getHiddenElementDimensions(element)
    let elementOuterHeight = elementDimensions.height
    let elementOuterWidth = elementDimensions.width
    let targetOuterHeight = target.offsetHeight
    let targetOuterWidth = target.offsetWidth
    let targetOffset = target.getBoundingClientRect()
    let windowScrollTop = this.getWindowScrollTop()
    let windowScrollLeft = this.getWindowScrollLeft()
    let viewport = this.getViewport()
    let top, left

    if (targetOffset.top + targetOuterHeight + elementOuterHeight > viewport.height) {
      top = targetOffset.top + windowScrollTop - elementOuterHeight
    } else {
      top = targetOuterHeight + targetOffset.top + windowScrollTop
    }

    if (targetOffset.left + targetOuterWidth + elementOuterWidth > viewport.width) {
      left = targetOffset.left + windowScrollLeft + targetOuterWidth - elementOuterWidth
    } else {
      left = targetOffset.left + windowScrollLeft
    }

    element.style.top = top + 'px'
    element.style.left = left + 'px'
  }

  static relativePosition (element, target) {
    let elementDimensions = element.offsetParent ? { width: element.outerWidth, height: element.outerHeight } : this.getHiddenElementDimensions(element)
    let targetHeight = target.offsetHeight
    let targetWidth = target.offsetWidth
    let targetOffset = target.getBoundingClientRect()
    let viewport = this.getViewport()
    let top, left

    if ((targetOffset.top + targetHeight + elementDimensions.height) > viewport.height) { top = -1 * (elementDimensions.height) } else { top = targetHeight }

    if ((targetOffset.left + elementDimensions.width) > viewport.width) { left = targetWidth - elementDimensions.width } else { left = 0 }

    element.style.top = top + 'px'
    element.style.left = left + 'px'
  }
  static getHiddenElementOuterHeight (element) {
    element.style.visibility = 'hidden'
    element.style.display = 'block'
    let elementHeight = element.offsetHeight
    element.style.display = 'none'
    element.style.visibility = 'visible'

    return elementHeight
  }

  static getHiddenElementOuterWidth (element) {
    element.style.visibility = 'hidden'
    element.style.display = 'block'
    let elementWidth = element.offsetWidth
    element.style.display = 'none'
    element.style.visibility = 'visible'

    return elementWidth
  }

  static getHiddenElementDimensions (element) {
    let dimensions = {}
    element.style.visibility = 'hidden'
    element.style.display = 'block'
    dimensions.width = element.offsetWidth
    dimensions.height = element.offsetHeight
    element.style.display = 'none'
    element.style.visibility = 'visible'

    return dimensions
  }

  static fadeIn (element, duration) {
    element.style.opacity = 0

    let last = +new Date()
    let opacity = 0
    let tick = function () {
      opacity = +element.style.opacity + (new Date().getTime() - last) / duration
      element.style.opacity = opacity
      last = +new Date()

      if (+opacity < 1) {
        (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16)
      }
    }

    tick()
  }

  static fadeOut (element, ms) {
    let opacity = 1
    let interval = 50
    let duration = ms
    let gap = interval / duration

    let fading = setInterval(() => {
      opacity -= gap

      if (opacity <= 0) {
        opacity = 0
        clearInterval(fading)
      }

      element.style.opacity = opacity
    }, interval)
  }

  static getUserAgent () {
    return navigator.userAgent
  }

  static appendChild (element, target) {
    if (this.isElement(target)) {
      target.appendChild(element)
    } else if (target.el && target.el.nativeElement) {
      target.el.nativeElement.appendChild(element)
    } else {
      throw new Error('Cannot append ' + target + ' to ' + element)
    }
  }

  static scrollInView (container, item) {
    let borderTopValue = getComputedStyle(container).getPropertyValue('borderTopWidth')
    let borderTop = borderTopValue ? parseFloat(borderTopValue) : 0
    let paddingTopValue = getComputedStyle(container).getPropertyValue('paddingTop')
    let paddingTop = paddingTopValue ? parseFloat(paddingTopValue) : 0
    let containerRect = container.getBoundingClientRect()
    let itemRect = item.getBoundingClientRect()
    let offset = (itemRect.top + document.body.scrollTop) - (containerRect.top + document.body.scrollTop) - borderTop - paddingTop
    let scroll = container.scrollTop
    let elementHeight = container.clientHeight
    let itemHeight = this.getOuterHeight(item)

    if (offset < 0) {
      container.scrollTop = scroll + offset
    } else if ((offset + itemHeight) > elementHeight) {
      container.scrollTop = scroll + offset - elementHeight + itemHeight
    }
  }

  static clearSelection () {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        window.getSelection().empty()
      } else if (window.getSelection().removeAllRanges &&
        window.getSelection().rangeCount > 0 &&
        window.getSelection().getRangeAt(0).getClientRects().length > 0) {
        window.getSelection().removeAllRanges()
      }
    } else if (document['selection'] && document['selection'].empty) {
      try {
        document['selection'].empty()
      } catch (error) {
        // ignore IE bug
      }
    }
  }
}
