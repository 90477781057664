import PropTypes from 'prop-types'
import React from 'react'
import Header from '../../components/Header'
import '../../styles/main.scss'
import 'bootstrap/dist/js/bootstrap'
import { formatDateTime } from '../../utils/localization'

const Footer = () => {
  // eslint-disable-next-line no-undef
  const version = __VERSION__
  // eslint-disable-next-line no-undef
  const buildDate = __BUILD_DATE__
  return <footer className='otp-footer container-fluid'>
    <div className='offset-1 col-10'>
      <div className='row justify-content-center'>
        Copyright 2018. OTP Ingatlanpont <div className='w-100 d-block d-sm-none' />({version + ' ' + formatDateTime(buildDate)})
      </div>
    </div>
  </footer>
}

export const CoreLayout = ({ children }) => (
  <div className='container-fluid'>
    <div className='row align-items'>
      <Header />
      <div className='container-fluid content-wrapper'>
        <div className='page-center-content'>
          {children}
        </div>
      </div>
      <Footer />
    </div>
  </div>
)

CoreLayout.propTypes = {
  children : PropTypes.element.isRequired
}

export default CoreLayout
