/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'primereact/components/button/Button'

const defaultProps = {
  onClick: function () {},
  className: 'btn',
  url: null
}

const propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
}

class ActionButton extends Component {
  handleClick () {
    return this.props.onClick()
  }

  render () {
    let className = this.props.className
    let url = false

    if (this.props.url) {
      if (this.props.url !== '#') {
        url = true
      }

      if (!url) {
        return (<a target='_blank' className={className}>{this.props.children}</a>)
      }

      return (<a href={this.props.url} target='_blank' className={className}>{this.props.children}</a>)
    }

    return (
      <Button label={this.props.children} onClick={() => this.handleClick()} className={className} />
    )
  }
}

ActionButton.displayName = 'PopupAction'
ActionButton.propTypes = propTypes
ActionButton.defaultProps = defaultProps

export default ActionButton
