/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropdown from '../../components/Common/UFPrimeReact/UFDropdown'
import { InputText } from 'primereact/components/inputtext/InputText'
import { iHelp } from '../../utils/commonFunctions'

export default class Address extends Component {
  constructor (props) {
    super(props)

    const { address } = this.props

    this.state = {
      country: address ? address.country : '',
      zip: address && address.zip ? address.zip : '',
      settlement: address && address.settlement ? address.settlement : '',
      publicDomainName: address && address.publicDomainName ? address.publicDomainName : '',
      afterPublicDomain: address && address.afterPublicDomain ? address.afterPublicDomain : '',
      lotNumber: address && address.lotNumber ? address.lotNumber : '',
      foreignAddress: address && address.foreignAddress ? address.foreignAddress : ''
    }
    this.setValue = this.setValue.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  static propTypes = {
    address: PropTypes.object,
    masterData: PropTypes.object,
    updateAddress: PropTypes.func.isRequired,
    helpkey: PropTypes.string,
    helptextclassname: PropTypes.string
  }

  setValue (ev) {
    this.setState({ [ev.target.name]: ev.target.value })
    this.props.updateAddress({ ...this.state, [ev.target.name]: ev.target.value })
  }

  onChange (ev) {
    this.setState({ [ev.name]: ev.value })
    if (ev.value === 'Magyarország') {
      this.setState({ foreignAddress: '' })
      this.props.updateAddress({ ...this.state, [ev.name]: ev.value, foreignAddress: '' })
    } else {
      this.props.updateAddress({ ...this.state, [ev.name]: ev.value })
    }
  }

  render () {
    const { Country } = this.props.masterData
    const countries = [{ label: 'Kérjük válasszon!', value: null }]
    Object.entries(Country).forEach(([key, value]) => { countries.push({ label: value[0].content.countryName, value: value[0].content.countryName }) })

    return <div>
      <div className='form-row'>
        <div className='form-group col-md-3'>
          <label className='text-uppercase'>Ország</label>
          <Dropdown options={countries}
            name='country'
            readOnly
            value={this.state.country}
            onChange={this.onChange}
            autoWidth={false}
            helptext={this.props.helpkey ? iHelp(this.props.masterData, this.props.helpkey) : ''}
            helptextclassname={this.props.helptextclassname}
          />
        </div>
      </div>
      <div className='form-row'>
        <div className='form-group col-lg-2 col-md-4 col-sm-12'>
          <label className='text-uppercase'>Irányítószám</label>
          <InputText className='form-control' placeholder='Irányítószám'
            name='zip'
            value={this.state.zip}
            disabled={this.state.country !== 'Magyarország'}
            onChange={this.setValue} />
        </div>
        <div className='form-group col-lg-3 col-md-8 col-sm-12'>
          <label className='text-uppercase'>Település</label>
          <InputText className='form-control' placeholder='Település'
            name='settlement'
            value={this.state.settlement}
            disabled={this.state.country !== 'Magyarország'}
            onChange={this.setValue} />
        </div>
        <div className='form-group col-lg-3 col-md-6 col-sm-12'>
          <label className='text-uppercase text-nowrap'>Közterület név és típus</label>
          <InputText className='form-control' placeholder='Közterület név és típus'
            name='publicDomainName'
            value={this.state.publicDomainName}
            disabled={this.state.country !== 'Magyarország'}
            onChange={this.setValue} />
        </div>
        <div className='form-group col-lg-2 col-md-3 col-sm-12'>
          <label className='text-uppercase'>Házszám</label>
          <InputText className='form-control' placeholder='Házszám'
            name='afterPublicDomain'
            value={this.state.afterPublicDomain}
            disabled={this.state.country !== 'Magyarország'}
            onChange={this.setValue} />
        </div>
        <div className='form-group col-lg-2 col-md-3 col-sm-12'>
          <label className='text-uppercase'>Helyrajzi szám</label>
          <InputText className='form-control' placeholder='Helyrajzi szám'
            name='lotNumber'
            value={this.state.lotNumber}
            disabled={this.state.country !== 'Magyarország'}
            onChange={this.setValue} />
        </div>
      </div>
      <div className='form-row'>
        <div className='form-group col'>
          <label className='text-uppercase'>Külföldi cím</label>
          <InputText className='form-control' placeholder='Külföldi cím'
            name='foreignAddress'
            value={this.state.foreignAddress}
            disabled={this.state.country === 'Magyarország'}
            onChange={this.setValue} />
        </div>
      </div>
    </div>
  }
}
