import { push } from 'connected-react-router'
import Cookies from 'universal-cookie'
import { clearFetchAction } from '../../../store/fetchReducer'
import { fetchSummary } from '../../../containers/SummaryWrapper'

const ssoErrorCodeToMessage = {
  passwordExpired: 'Az Ön jelszava új vagy lejárt ezért meg kell változtatnia.',
  wrongPassword: 'Nem megfelelő jelszó.',
  unknownEmail: 'A rendszerben nem regisztrált e-mail címet adott meg.',
  userAbandoned: 'A megadott e-mail címhez tartozó felhasználó inaktív, vagy ki van tiltva. ' +
  'A 365 nap óta inaktív felhasználókat a rendszer automatikusan inaktív státuszúra állítja. ' +
  'Ha hosszú ideje nem lépett be, akkor a lenti Elfelejtettem a jelszavamat hivatkozásra kattintva, ' +
  'egy új jelszó megadásával újraaktiválhatja a bejelentkezését. Figyeljen arra, hogy a jelszava nem lehet a ' +
  'korábban megadott 6 jelszó egyike sem. ' +
  'A rendszer 5 percre kitiltja azokat a felhasználókat, akik 5 perc alatt egymást követően ötször is elírták a jelszavukat. ' +
  'Ha ez történt, akkor várjon pár percet és ismét be fog tudni jelentkezni.',
  invalidOldPassword: 'Az aktuális jelszó hibásan lett megadva. ' +
  'Csak az aktuális jelszó ismeretében lehet új jelszót beállítani.',
  invalidNewPassword: 'A megadott két jelszó nem egyezik.',
  invalidRequest: 'Elfelejtett jelszó funkció esetén nem megfelelő a token, amivel a jelszót meg szeretnék változtatni.',
  weakPassword: 'Nem felel meg a jelszó a jelszó követelményeknek: ' +
  '1. Legalább 8 karakter ' +
  '2. 8-14 karakter között tartalmaznia kell az alábbi négy csoportból háromat: ' +
  'kisbetű, nagybetű, szám, speciális karakter (szóközt is beleértve) ' +
  '3. 15 vagy hosszabb jelszó esetén annyit kell vizsgálni, hogy ne csak nemlátható karakter legyen és ' +
  'összesen legalább 4-féle karakter legyen azért benne (de az lehet mind kisbetű is, vagy szám is) ' +
  '4. Az jelszó nem kezdődhet vagy végződhed nemlátható karakterrel',
  similarPasswords: 'Az új jelszónak a korábbitól legalább 4 karakterben el kell térnie.',
  matchPreviousPassword: 'Az új jelszó nem lehet az előző 6 db jelszó valamelyike.',
  passwordChangeLimitExceed: 'Naponta maximum 10 alkalommal lehet jelszót módosítani.',
  alreadyExists: 'A felhasználó már létezik az  email cím alapján (regisztrációkor).',
  invalidEmail: 'Nem megfelelő email cím.',
  invalidName: 'Nem megfelelő név.',

  // cannotModify Ügyfélfiókos felhasználó esetén nincs lehetőség a portál oldalról adatmódosítást végezni
  // notDeletable Ügyfélfiókos felhasználó esetén nincs lehetőség a portál oldalról a regisztrációt törölni

  // ügyfélfiók specifikus kódok
  ssoServerConnect: 'A kiszolgáló nem elérhető.',
  newPasswordNotMatch: 'A megadott új jelszavak nem egyeznek meg.',
  loginNotAllowed: 'Ön rendelkezik honlapunkon regisztrációval, azonban kapcsolatIPont nyitásához kérjük keresse fel kollégánkat!'
}

export const ssoErrorMessage = (ssoErrorCode) => ssoErrorCodeToMessage[ssoErrorCode]

const cookies = new Cookies()

// ------------------------------------
// Constants
// ------------------------------------
// There are three possible states for our login
// process and we need actions for each of them
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGIN_FORGOT = 'LOGIN_FORGOT'
export const LOGIN_CHANGE = 'LOGIN_CHANGE'
export const LOGIN_NORMAL = 'LOGIN_NORMAL'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

const initialState = { isAuthenticated: false, errorMessage: '', mode: 'normal' }

// ------------------------------------
// Actions
// ------------------------------------
export const requestLogin = (creds) => {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    creds,
    errorMessage: undefined
  }
}

const receiveLogin = (user) => {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    user,
    errorMessage: undefined
  }
}

const loginError = (errorCode, errorMessage) => {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    errorCode,
    errorMessage,
    user: undefined
  }
}

const passwordChangeError = (errorCode, errorMessage) => {
  return {
    type: LOGIN_FAILURE,
    errorCode,
    errorMessage
  }
}

export const requestLogout = () => ({
  type: LOGOUT_REQUEST
})

export const receiveLogout = () => ({
  type: LOGOUT_SUCCESS
})

export const logoutError = (errorMessage) => ({
  type: LOGOUT_FAILURE,
  errorMessage
})

export const forgotAction = () => ({ type: LOGIN_FORGOT })

export const passwordChangeAction = () => ({ type: LOGIN_CHANGE })

export const normalLoginAction = () => ({ type: LOGIN_NORMAL })

export const cleanLogin = () => {
  return {
    type: LOGIN_NORMAL,
    isFetching: false,
    isAuthenticated: false,
    errorCode: undefined,
    errorMessage: undefined,
    user: undefined
  }
}

export const actions = {
  requestLogin,
  receiveLogin,
  loginError,
  forgotAction,
  passwordChangeAction
}

const isExpired = (state, action) => state.mode === 'expired' || action.errorCode === 'passwordExpired'

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOGIN_REQUEST] : (state, action) => {
    let result = Object.assign({}, state, action)
    delete result.creds
    return result
  },
  [LOGIN_SUCCESS] : (state, action) => {
    return { ...state, ...action, mode: 'normal' }
  },
  [LOGIN_FAILURE] : (state, action) => {
    setGlobalOtpipAuth(undefined)
    return { ...state, ...action, mode: isExpired(state, action) ? 'expired' : state.mode }
  },
  [LOGIN_FORGOT] : (state, action) => ({ ...state, ...action, mode: 'forgot' }),
  [LOGIN_CHANGE] : (state, action) => ({ ...state, ...action, mode: 'change' }),
  [LOGIN_NORMAL] : (state, action) => ({ ...state, ...action, mode: 'normal', errorCode: undefined, errorMessage: undefined }),
  [LOGOUT_REQUEST] : (state, action) => Object.assign({}, state, { logoutProcess: true }),
  [LOGOUT_SUCCESS] : (state, action) => {
    return { ...initialState }
  },
  [LOGOUT_FAILURE] : (state, action) => Object.assign({}, initialState)
}

// ------------------------------------
// Reducer
// ------------------------------------
export default function loginReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

let globalOtpipAuth
let globalUserSsoId

export const getGlobalOtpipAuth = () => globalOtpipAuth
export const setGlobalOtpipAuth = (otpipAuth) => { globalOtpipAuth = otpipAuth }

export const doLogin = (dispatch, creds) => {
  if (creds && creds.mode && ((creds.mode === 'expired' || creds.mode === 'change') && creds.newPassword1 !== creds.newPassword2)) {
    const code = 'newPasswordNotMatch'
    creds.mode === 'change' ? dispatch(passwordChangeError(code, ssoErrorMessage(code))) : dispatch(loginError(code, ssoErrorMessage(code)))
    return
  }
  // We dispatch requestLogin to kickoff the call to the API
  dispatch(requestLogin(creds))

  if (creds === undefined) {
    // a felhasználó már másik rendszerben bejelentkezett, a szervertől csak le kell
    // kérni az adatokat, de nem kell újra bejelentkeztetni
    // eslint-disable-next-line no-undef
    return fetch(API_URL_JS + '/loggedInUser', { method: 'GET', credentials: 'include' })
      .then(response => {
        if (response.ok) {
          response.text().then(
            text => {
              const user = JSON.parse(text)
              dispatch(receiveLogin({ ...user }))
              if (globalUserSsoId  && globalUserSsoId !== user.ssoUserId) {
                dispatch(clearFetchAction)
                // erre azért van szükség, mert a HeaderComponent-be bekerült a summary, de
                // a header render hamarabb fut le, mint hogy a clearFetchAction megtörténne
                // a SummaryWrapper nem tud új fetchet hívni, ha kirörlik "alóla" a summary-t
                dispatch(fetchSummary())
              }
              globalUserSsoId = user.ssoUserId
            },
            errorResponse => {
              // TODO: felugró ablakban figyelmeztetés
              alert('Másik alkalmazásban átjelentkezett, de az új felhasználó az kapcsolatIPonton nincs regisztrálva.')
              dispatch(push('/login'))
            }
          )
        } else {
          dispatch(loginError(undefined, ''))
          dispatch(push('/login'))
        }
      },
      err => console.log('Error: ', err)
      ).catch(
        err => console.log('Error: ', err)
      )
  } else if (creds.mode === 'normal' || creds.mode === 'expired') {
    // SSO bejelentkezés: a felhasználó az ügyfélfiókban nyomta meg a bejelentkezés gombot, az
    // űrlapon kitöltött email cím és jelszó alapján kell elvégezni a bejelentkeztetést
    let config = {
      method: 'POST',
      headers: { 'Content-Type':'application/x-www-form-urlencoded' },
      body: `email=${creds.username}&password=${creds.password}&newPassword=${creds.newPassword1}`
    }
    // eslint-disable-next-line no-undef
    return fetch(API_URL_JS + '/login', config)
      .then(response => {
        if (response.ok) {
          response.text().then(
            text => {
              let o
              try {
                o = JSON.parse(text)
                const cookieName = o[COOKIE_NAME] // eslint-disable-line no-undef
                if (cookieName === undefined || o.errorCode === 500 || o.error !== undefined) {
                  const errorMessage = ssoErrorMessage(o.error) || 'Ismeretlen hiba.'
                  dispatch(loginError(o.error, 'Sikertelen belépés. ' + errorMessage))
                } else {
                  globalOtpipAuth = cookieName
                  document.cookie = `${COOKIE_NAME}=${cookieName};domain=.${COOKIE_DOMAIN};Path=/` // eslint-disable-line no-undef
                  dispatch(receiveLogin({ ...o.user }))
                  globalUserSsoId = o.user.ssoUserId
                  dispatch(clearFetchAction)
                  dispatch(push('/'))
                }
              } catch (e) {
                dispatch(loginError(undefined, 'Sikertelen belépés. Ismeretlen hiba.'))
              }
            },
            errorResponse => {
              dispatch(loginError(undefined, 'Sikertelen belépés. Belső rendszer hiba.'))
            }
          )
        } else {
          dispatch(loginError(undefined, 'Sikertelen belépés.'))
        }
      },
      err => {
        console.log('Error: ', err)
        dispatch(loginError(undefined, 'Sikertelen belépés. A kiszolgáló nem elérhető.'))
      }
      ).catch(
        err => {
          console.log('Error: ', err)
          dispatch(loginError(undefined, 'Sikertelen belépés. A kiszolgáló nem elérhető.'))
        }
      )
  } else if (creds.mode === 'forgot') {
    let config = {
      method: 'POST',
      headers: { 'Content-Type':'application/x-www-form-urlencoded' },
      body: `email=${creds.username}`
    }
    // eslint-disable-next-line no-undef
    return fetch(API_URL_JS + '/forgotPassword', config)
      .then(response => {
        if (response.ok) {
          response.text().then(
            text => {
              const o = JSON.parse(text)
              console.log('forgotPassword response: ', o)
              if (o.error) {
                dispatch(loginError(undefined, 'Az e-mail elküldése sikertelen. ' + ssoErrorMessage(o.error)))
              } else {
                dispatch(loginError(undefined, 'Az e-mail elküldése megtörtént.' + (o.token ? ' Token: ' + o.token : '')))
              }
            }
          )
        } else {
          dispatch(loginError(undefined, 'Nem sikerült a jelszó emlékeztető elküldése.'))
        }
      }
      ).catch(
        err => {
          console.log('Error: ', err)
          dispatch(loginError(undefined, 'Nem sikerült a jelszó emlékeztető elküldése. A kiszolgáló nem elérhető.'))
        }
      )
  } else if (creds.mode === 'change') {
    let config = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type':'application/x-www-form-urlencoded' },
      body: `userId=${creds.ssoUserId}&email=${creds.username}&password=${creds.password}&newPassword=${creds.newPassword1}`
    }
    // eslint-disable-next-line no-undef
    return fetch(API_URL_JS + '/passwordChange', config)
      .then(response => {
        if (response.ok) {
          response.text().then(
            text => {
              const o = JSON.parse(text)
              console.log('forgotPassword response: ', o)
              if (o.error) {
                dispatch(passwordChangeError(undefined, 'A jelszó módosítása sikertelen. ' + ssoErrorMessage(o.error)))
              } else {
                dispatch(passwordChangeError(undefined, 'A jelszó módosítása megtörtént.'))
              }
            }
          )
        } else {
          dispatch(passwordChangeError(undefined, 'A jelszó módosítása sikertelen. Ismeretlen hiba.'))
        }
      }
      ).catch(
        err => console.log('Error: ', err)
      )
  }
}

export const doLogout = (dispatch) => {
  let config = {
    method: 'GET',
    credentials: 'include'
  }

  // dispatch(push('/login'))
  // We dispatch requestLogin to kickoff the call to the API
  dispatch(requestLogout())

  // eslint-disable-next-line no-undef
  const fr = fetch(API_URL_JS + '/logout2', config)
    .then(response => {
      if (response.ok) {
        dispatch(receiveLogout())
        dispatch(push('/login'))
        cookies.remove(COOKIE_NAME, { domain: '.' + COOKIE_DOMAIN, path: '/' }) // eslint-disable-line no-undef
      } else {
        dispatch(logoutError('Sikertelen kijelentkezés.'))
      }
    }).catch(err => console.log('Error: ', err))

  return fr
}
