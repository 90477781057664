/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { formatDateTime } from '../../../utils/localization'
import { notificationIcon } from '../modules/notification'

class NotificationsSummary extends Component {
  render () {
    const { events } = this.props
    const eventsList = events['newEvent']
    const noticedEventList = events['noticedEvent']

    let rendered
    let count = 0

    const EventRow = ({ event }) => (
      <div className='d-flex text-left form-group align-items-center justify-content-center'>
        <div className='col-auto no-gutter'>{notificationIcon(event.eventType)}</div>
        <div className='pl-0'>{event.eventMessage} <b>{formatDateTime(event.created)}</b></div>
      </div>)

    EventRow.propTypes = {
      event: PropTypes.object
    }

    if (eventsList && eventsList.length > 0) {
      rendered =
        <div className='notification-box col-12'>
          <div className=''>
            { count < 3 ? eventsList.map((event, index) => {
              return count++ < 3
                ? <EventRow event={event} key={index} />
                : null
            }) : null}
          </div>
          <div className='mt-4 text-center'>
            <Link to={'/ertesitesek'} className='green-link'>Értesítések részletes listája -></Link>
          </div>
        </div>
    } else if (noticedEventList && noticedEventList.length > 0) {
      rendered = <div className='notification-box col-12'>
        <div className='valign'>
          <Link to={'/ertesitesek'} className='green-link'>Értesítések részletes listája -></Link>
        </div>
      </div>
    } else {
      rendered = null
    }
    return rendered
  }
}

NotificationsSummary.propTypes = {
  events: PropTypes.object
}

export default NotificationsSummary
