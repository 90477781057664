/* eslint-disable no-restricted-globals */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
//import 'babel-polyfill'
import './utils/localization'
import 'bootstrap/scss/bootstrap.scss'
import 'primereact/resources/primereact.min.css'
import './styles/primetheme/theme.scss'
import 'font-awesome/css/font-awesome.css'
import 'pure-react-carousel/dist/react-carousel.es.css'
import React from 'react'
import ReactDOM from 'react-dom'
import Popup from './components/Common/UFPopup/index'
import createStore, { browserHistory } from './store/createStore'
import AppContainer from './containers/AppContainer'
import packageJson from '../package.json'
import preval      from 'preval.macro'

// ========================================================
// Browser and cookie check
// ========================================================
function hideWarning () {
  document.cookie = 'hidewarning=1'
}

function checkWarning () {
  try {
    var ret = document.cookie.indexOf('hidewarning=') !== -1
    return ret
  } catch (e) {
    return false
  }
}

function testCookies () {
  try {
    // Create cookie
    document.cookie = 'cookietest=1'
    var ret = document.cookie.indexOf('cookietest=') !== -1
    // Delete cookie
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'
    return ret
  } catch (e) {
    return false
  }
}

function detectBrowser (userAgentString) {
  if (!userAgentString) {
    return null
  }

  var browsers = [
    [ 'edge', /Edge\/([0-9._]+)/ ],
    [ 'yandexbrowser', /YaBrowser\/([0-9._]+)/ ],
    [ 'chrome', /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/ ],
    [ 'crios', /CriOS\/([0-9.]+)(:?\s|$)/ ],
    [ 'firefox', /Firefox\/([0-9.]+)(?:\s|$)/ ],
    [ 'opera', /Opera\/([0-9.]+)(?:\s|$)/ ],
    [ 'opera', /OPR\/([0-9.]+)(:?\s|$)$/ ],
    [ 'ie', /Trident\/7\.0.*rv:([0-9.]+)\).*Gecko$/ ],
    [ 'ie', /MSIE\s([0-9.]+);.*Trident\/[4-7].0/ ],
    [ 'ie', /MSIE\s(7\.0)/ ],
    [ 'bb10', /BB10;\sTouch.*Version\/([0-9.]+)/ ],
    [ 'android', /Android\s([0-9.]+)/ ],
    [ 'ios', /Version\/([0-9._]+).*Mobile.*Safari.*/ ],
    [ 'safari', /Version\/([0-9._]+).*Safari/ ]
  ]

  return browsers.map(function (rule) {
    if (rule[1].test(userAgentString)) {
      var match = rule[1].exec(userAgentString)
      var version = match && match[1].split(/[._]/).slice(0, 3)

      if (version && version.length < 3) {
        Array.prototype.push.apply(version, (version.length === 1) ? [0, 0] : [0])
      }

      return {
        name: rule[0],
        version: version.join('.')
      }
    }
  }).filter(Boolean).shift()
}

if (!checkWarning()) {
  var cookiesEnabled = false
  var supportedBrowser = false
  if (typeof navigator !== 'undefined' && navigator) {
    const browser = detectBrowser(navigator.userAgent)
    if (browser && browser.name && browser.version) {
      console.log(browser.name + ' ' + browser.version)
      const v = browser.version.split('.')
      switch (browser.name) {
        case 'ie':
          if (Number(v[0]) >= 11) {
            supportedBrowser = true
          }
          break
        case 'firefox':
          /* https://en.wikipedia.org/wiki/Firefox_version_history */
          if (Number(v[0]) >= 53) {
            supportedBrowser = true
          }
          break
        case 'chrome':
          /* https://en.wikipedia.org/wiki/Google_Chrome_version_history */
          if (Number(v[0]) >= 58) {
            supportedBrowser = true
          }
          break
        case 'safari':
          /* https://en.wikipedia.org/wiki/Safari_version_history */
          supportedBrowser = true
          break
        case 'opera':
          /* https://en.wikipedia.org/wiki/History_of_the_Opera_web_browser */
          if (Number(v[0]) >= 45) {
            supportedBrowser = true
          }
          break
        case 'edge':
          /* https://en.wikipedia.org/wiki/Microsoft_Edge */
          if (Number(v[0]) >= 14) {
            supportedBrowser = true
          }
          break
        case 'android':
          if (Number(v[0]) >= 4) {
            supportedBrowser = true
          }
          break
        case 'ios':
          supportedBrowser = true
          break
      }
    }
  }
  cookiesEnabled = testCookies()

  console.log('supportedBrowser: ' + supportedBrowser)
  console.log('cookiesEnabled: ' + cookiesEnabled)

  if (!supportedBrowser) {
    document.getElementById('browser_check_other').style.display = 'block'
    document.getElementById('browser_check_unsupported').style.display = 'block'
    if (cookiesEnabled) {
      const closeButton = document.getElementById('browser_check_close_button')
      closeButton.style.display = 'block'
      closeButton.addEventListener('click', function () {
        hideWarning()
        document.getElementById('browser_check_other').style.display = 'none'
      })
    }
  }

  if (!cookiesEnabled) {
    document.getElementById('browser_check_other').style.display = 'block'
    document.getElementById('browser_check_cookie').style.display = 'block'
  }

  // if (supportedBrowser && cookiesEnabled) {
  //  hideWarning()
  // }
}

// a fejlesztői környezetben fog csak 3000-es porton futni a web felület, ekkor másként kell meghatározni
// az API elérhetőségét
window.API_URL_JS = parseInt(location.port) === 3000
  ? location.protocol + '//' + location.hostname + ':8880/backend'
  : location.protocol + '//' + location.host + '/api'

// https://stackoverflow.com/questions/53028778/how-to-show-build-datetime-on-my-react-web-app-using-create-react-app
window.__VERSION__ = 'v' + packageJson.version
window.__BUILD_DATE__ = preval`module.exports = new Date().getTime();`

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__
const store = createStore(initialState)

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root')
const POPUP_CONTAINER = document.getElementById('popupContainer')

let render = () => {
  //const routes = require('./routes/index').default(store)

  ReactDOM.render(
    <AppContainer store={store} history={browserHistory}/>,
    MOUNT_NODE
  )
  ReactDOM.render(
    <Popup className='mm-popup' btnClass='ui-button' closeBtn closeHtml={null} wildClasses={false} closeOnOutsideClick={false} />,
    POPUP_CONTAINER
  )
}

// ========================================================
// Go!
// ========================================================
render()
