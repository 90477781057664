/*
 * Copyright 2020 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import { SummaryWrapper } from '../../../containers/SummaryWrapper'
import { UserIsAuthenticated } from '../../index'
import HomeView from '../components/HomeView'

const HomeViewContainer = UserIsAuthenticated(SummaryWrapper(HomeView))

export default HomeViewContainer
