/* eslint-disable react/jsx-no-bind */
/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import { helpIcon } from '../Icons'

export class InputText extends Component {
  static propTypes = {
    helptext: PropTypes.string,
    helptextclassname: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string
  }

  onFocus () {
    if (this.props.helptext) {
      this.helpElement.style.display = 'block'
    }
  }

  onBlur () {
    if (this.props.helptext) {
      this.helpElement.style.display = 'none'
    }
  }

  render () {
    var styleClass = classNames('ui-inputtext ui-state-default ui-corner-all ui-widget', this.props.className, {
      'ui-state-disabled': this.props.disabled
    })

    return <div>
      <input {...this.props} className={styleClass} onFocus={this.onFocus.bind(this)} onBlur={this.onBlur.bind(this)} />
      {this.props.helptext
        ? <div ref={(e) => { this.helpElement = e }} className={classNames('help-message', this.props.helptextclassname)}
          style={{ display: 'none' }}>{helpIcon}
          <div className='help-text' dangerouslySetInnerHTML={{ __html: this.props.helptext }} /></div>
        : null }
    </div>
  }
}
