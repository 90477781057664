/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React, { Component } from 'react'
import { Button } from 'primereact/components/button/Button'
import { useHistory } from 'react-router-dom'

/**
 * A react-router Link-jéhez hasonló PrimeReact-os nyomógomb
 */
export const LinkButton = ({label, to, className}) => {
  const history = useHistory()
  return (
    <Button
        className={className}
        label={label}
        onClick={() => {
          window.scrollTo(0, 0)
          history.push(to)
        }}
    />
  )
}

export default LinkButton
