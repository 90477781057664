/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { humanFileSize } from '../../utils/localization'
import classNames from 'classnames'
import { helpIcon } from './Icons'

export default class FileUpload extends Component {
  constructor (props) {
    super(props)
    this.onFileSelect = this.onFileSelect.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.state = {
      fileList: null
    }
  }

  static propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    onFilesChange: PropTypes.func.isRequired,
    helptext: PropTypes.string,
    helptextclassname: PropTypes.string
  }

  onFileSelect (event) {
    this.setState({ fileList: this.fileInput.files })
    this.props.onFilesChange(this.fileInput.files)
  }

  onFocus () {
    if (this.props.helptext) {
      this.helpElement.style.display = 'block'
    }
  }

  onBlur () {
    if (this.props.helptext) {
      this.helpElement.style.display = 'none'
    }
  }

  render () {
    const { buttonLabel } = this.props
    return <div className='fileUpload-box' tabIndex={0} onFocus={this.onFocus} onBlur={this.onBlur}>
      <label htmlFor='file-upload' className='ui-button ui-widget ui-state-default'>
        { buttonLabel }
      </label>
      {
        this.fileInput && this.fileInput.files.length > 0 ? <div>
          <div>{this.fileInput.files.length} állományt válaszott ki</div>
          <ul>
            {Array.from(this.fileInput.files).map(file => (
              <li key={file.name}>Név: {file.name}, méret: {humanFileSize(file.size)}</li>
            )
            )}
          </ul>
          <div>Összesen { humanFileSize(Array.from(this.fileInput.files).reduce((size, file) => size + file.size, 0)) }</div>
        </div>
          : <div>nincs kiválasztott állomány</div>
      }
      <input type='file' id='file-upload' style={{ display: 'none' }}
        multiple ref={(c) => { if (c) { this.fileInput = c } }} onChange={this.onFileSelect} />
      {this.props.helptext
        ? <div ref={(e) => { this.helpElement = e }} className={classNames('help-message', this.props.helptextclassname)}
          style={{ display: 'none' }}>{helpIcon}
          <div className='help-text' dangerouslySetInnerHTML={{ __html: this.props.helptext }} /></div>
        : null }
    </div>
  }
}
