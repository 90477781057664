/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import { connect } from 'react-redux'
import React, { Component } from 'react'
import { createFetchAction } from '../store/fetchReducer'
import fetchOnUpdate from '../utils/fetchOnUpdate'
import { groupBy, oMap } from '../utils/commonFunctions'

const convert = (data) => {
  const types = groupBy(Object.values(data), 'masterDataType')
  return Object.values(types).length > 0
    ? oMap(types, (x) => groupBy(x, 'key')) : {}
}

const MasterDataWrapper = (Child) => {
  const emptyFlags = { fetched: [], isLoaded: false, isLoading: false, error: '' }
  const selectFlags = (state) =>
    state.fetch.masterData && state.fetch.masterData.all ? state.fetch.masterData.all : emptyFlags
  const selectList = (state) => selectFlags(state).fetched

  const mapDispatchToProps = (dispatch, { processType, processId }) => ({
    actions: { onFetchList: () =>
      dispatch(createFetchAction(
        {
          endPoint: 'lists/masterDataList?deleted=false',
          statePath: 'masterData',
          subPath: 'all',
          convert
        }))
    }
  })

  const mapStateToProps = (state, ownProps) => {
    let props = {
      masterData: selectList(state),
      isLoading: selectFlags(state).isLoading,
      isLoaded: selectFlags(state).isLoaded,
      hasError: selectFlags(state).hasError,
      error: selectFlags(state).error,
      isFetchNeeded: !selectFlags(state).isLoaded && !selectFlags(state).isLoading
    }
    return props
  }

  class Wrapper extends Component {
    render () {
      return <Child {...this.props} />
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(
      fetchOnUpdate([], (params, actions) => {
        actions.onFetchList()
      }, 'isFetchNeeded')
      (Wrapper)
  )
}

export default MasterDataWrapper
