import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { form, from } from 'react-inform'
import { Button } from 'primereact/components/button/Button'
import { InputText } from '../../../components/Common/UFPrimeReact/UFInputText'
import { UFCalendar } from '../../../components/Common/UFPrimeReact/UFCalendar'
import Checkbox from '../../../components/Common/UFPrimeReact/UFCheckbox'
import Popup from '../../../components/Common/UFPopup/index'
import Dropdown from '../../../components/Common/UFPrimeReact/UFDropdown'
import { dateFormat, hu } from '../../../utils/localization'
import FileUpload from '../../../components/Common/FileUpload'
import { warningIcon } from '../../../components/Common/Icons'
import Address from '../../../components/Common/Address'
import Phone from '../../../components/Common/Phone'
import { iHelp, phoneCountryCode, phoneNumber } from '../../../utils/commonFunctions'
import { PhoneListEditor } from './PhoneListEditor'

const exists = v => Boolean(v)

let originalUgyfelfiokPhone = null
let originalEmail = null
let moreDetails = null

const validatePhoneAndEmail = (email, ugyfelfiokPhone) => {
  return !(JSON.stringify(originalUgyfelfiokPhone) !== JSON.stringify(ugyfelfiokPhone) && originalEmail !== email)
}

const validateMoreDetailsValue = (value) => {
  return moreDetails ? exists(value) : true
}

const validateAddress = (value) => {
  return moreDetails
    ? (value ? value.settlement && ((value.publicDomainName && value.afterPublicDomain) || value.lotNumber) : true)
    : true
}

const validateUgyfelfiokPhone = (value) => {
  return value ? (value.countryCode && value.phoneNumber) : true
}

const validatePhoneCountry = (value) => {
  return value && value.countryCode ? phoneCountryCode.test(value.countryCode) : true
}
const validatePhoneNumber = (value) => {
  return value && value.phoneNumber ? phoneNumber.test(value.phoneNumber) : true
}
const validatePhoneCountryInArray = (value) => {
  if (value) {
    let count = 0
    value.map(v => {
      if (!validatePhoneCountry(v)) {
        count++
      }
    })
    return count === 0
  } else {
    return true
  }
}
const validatePhoneNumberInArray = (value) => {
  if (value) {
    let count = 0
    value.map(v => {
      if (!validatePhoneNumber(v)) {
        count++
      }
    })
    return count === 0
  } else {
    return true
  }
}

const validateVerySmallFile = (value) => {
  if (value) {
    const fileList = Array.from(value)
    const smallFiles = fileList.filter(file => file.size < 100)
    return smallFiles.length === 0
  } else {
    return true
  }
}

const rulesMap = {
  ugyfelfiokPhone: {
    'Kérjük töltse ki a mezőt!': validateUgyfelfiokPhone,
    'A megadott országkód nem megfelelő!': validatePhoneCountry,
    'A telefonszám nem megfelelő, tartalmaz nem megengedett karaktereket vagy túl rövid vagy túl hosszú!' : validatePhoneNumber
  },
  newPhone: {
    'A megadott országkód nem megfelelő!': validatePhoneCountry,
    'A telefonszám nem megfelelő, tartalmaz nem megengedett karaktereket vagy túl rövid vagy túl hosszú!' : validatePhoneNumber
  },
  phones: {
    'A megadott országkód nem megfelelő!': validatePhoneCountryInArray,
    'A telefonszám nem megfelelő, tartalmaz nem megengedett karaktereket vagy túl rövid vagy túl hosszú!' : validatePhoneNumberInArray
  },
  email: {
    'Kérjük töltse ki a mezőt!': exists,
    'Nem megengedett az e-mail cím és az kapcsolatIPont telefonszám együttes módosítása!':
      (email, values) => validatePhoneAndEmail(email, values.ugyfelfiokPhone),
    'Az email cím formátuma nem megfelelő!': (email) =>
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(email)
  },
  name: {
    'Kérjük töltse ki a mezőt!': validateMoreDetailsValue
  },
  birthName: {
    'Kérjük töltse ki a mezőt!': validateMoreDetailsValue
  },
  birthPlace: {
    'Kérjük töltse ki a mezőt!': validateMoreDetailsValue
  },
  birthDate: {
    'Kérjük töltse ki a mezőt!': validateMoreDetailsValue
  },
  motherName: {
    'Kérjük töltse ki a mezőt!': validateMoreDetailsValue
  },
  idCardType: {
    'Kérjük válasszon!': validateMoreDetailsValue
  },
  idCardNumber: {
    'Kérjük töltse ki a mezőt!': validateMoreDetailsValue
  },
  address: {
    'Kérjük töltse ki a mezőt!': validateAddress
  },
  files: {
    'A sikeres adatmódosításhoz legalább egy dokumentum feltöltése szükséges!': validateMoreDetailsValue,
    'Ügyféladat módosítás alapjául szolgáló dokumentumként nem tölthető fel "üres" tartalom': validateVerySmallFile
  }
}

class IndividualProfile extends Component {
  constructor (props) {
    super(props)
    const { customer } = this.props

    originalEmail = customer.email
    originalUgyfelfiokPhone = { ...customer.ugyfelfiokPhone, countryCode: '+' + customer.ugyfelfiokPhone.countryCode }
    moreDetails = false

    let tempPhones = customer.phones.slice()
    for (let i in tempPhones) {
      tempPhones[i] = { ...tempPhones[i], countryCode: '+' + tempPhones[i].countryCode }
    }

    this.state = {
      warningMessage: '',
      newPhone: '',
      newPhoneError: '',
      moreDetails: false,
      customer: {
        email: customer.email,
        ugyfelfiokPhone: { ...customer.ugyfelfiokPhone, countryCode: '+' + customer.ugyfelfiokPhone.countryCode },
        phones: tempPhones,
        nationality: customer.nationality,
        name: customer.name ? customer.name : '',
        birthName: customer.birthName ? customer.birthName : '',
        birthPlace: customer.birthPlace ? customer.birthPlace : '',
        birthDate: customer.birthDate ? new Date(customer.birthDate) : '',
        motherName: customer.motherName ? customer.motherName : '',
        idCardType: customer.idCardType,
        idCardNumber: customer.idCardNumber ? customer.idCardNumber : '',
        notificationAddress: customer.notificationAddress,
        address: customer.address,
        taxId: customer.taxId ? customer.taxId : '',
        bankAccountNumber: customer.bankAccountNumber ? customer.bankAccountNumber : '',
        marketingEnabled: customer.marketingEnabled,
        financialEnabled: customer.financialEnabled
      },
      files: ''
    }

    this.setValue = this.setValue.bind(this)
    this.onValidatedDataChange = this.onValidatedDataChange.bind(this)
    this.setSelectedValue = this.setSelectedValue.bind(this)
    this.onValidatedSelectedDataChange = this.onValidatedSelectedDataChange.bind(this)
    this.onCheckboxChange = this.onCheckboxChange.bind(this)
    this.onCalendarChange = this.onCalendarChange.bind(this)
    this.onValidatedCalendarChange = this.onValidatedCalendarChange.bind(this)
    this.onFilesChange = this.onFilesChange.bind(this)
    this.onAddPhoneClick = this.onAddPhoneClick.bind(this)
    this.onDeletePhoneClick = this.onDeletePhoneClick.bind(this)
    this.updateAddress = this.updateAddress.bind(this)
    this.updatePhone = this.updatePhone.bind(this)
    this.updateNotificationAddress = this.updateNotificationAddress.bind(this)
  }

  componentWillMount () {
    this.setValuesToValidation()
  }

  componentWillUpdate () {
    const { form } = this.props
    if (this.state.warningMessage !== '' && form.isValid()) {
      this.setState({ warningMessage: '' })
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.customer !== this.props.customer) {
      const { customer } = nextProps

      let tempPhones = customer.phones.slice()
      for (let i in tempPhones) {
        tempPhones[i] = { ...tempPhones[i], countryCode: '+' + tempPhones[i].countryCode }
      }

      this.setState({
        customer: {
          email: customer.email,
          ugyfelfiokPhone: { ...customer.ugyfelfiokPhone, countryCode: '+' + customer.ugyfelfiokPhone.countryCode },
          phones: tempPhones,
          nationality: customer.nationality,
          name: customer.name ? customer.name : '',
          birthName: customer.birthName ? customer.birthName : '',
          birthPlace: customer.birthPlace ? customer.birthPlace : '',
          birthDate: customer.birthDate ? new Date(customer.birthDate) : '',
          motherName: customer.motherName ? customer.motherName : '',
          idCardType: customer.idCardType,
          idCardNumber: customer.idCardNumber ? customer.idCardNumber : '',
          notificationAddress: customer.notificationAddress,
          address: customer.address,
          taxId: customer.taxId ? customer.taxId : '',
          bankAccountNumber: customer.bankAccountNumber ? customer.bankAccountNumber : '',
          marketingEnabled: customer.marketingEnabled,
          financialEnabled: customer.financialEnabled
        }
      })
    }
  }

  setValuesToValidation () {
    const { form } = this.props
    form.onValues({ 'ugyfelfiokPhone': this.state.customer.ugyfelfiokPhone,
      'email': this.state.customer.email,
      'phones' : this.state.customer.phones,
      'name': this.state.customer.name,
      'birthName': this.state.customer.birthName,
      'birthPlace': this.state.customer.birthPlace,
      'birthDate': this.state.customer.birthDate,
      'motherName': this.state.customer.motherName,
      'idCardType': this.state.customer.idCardType,
      'idCardNumber': this.state.customer.idCardNumber,
      'address': this.state.customer.address
    })
  }

  setValue (ev) {
    this.setState({ customer: { ...this.state.customer, [ev.target.name]: ev.target.value } })
  }

  onValidatedDataChange (ev) {
    this.setValue(ev)
    switch (ev.target.name) {
      case 'email':
        const { email } = this.props.fields
        email.props.onChange(ev.target.value)
        break
      case 'name':
        const { name } = this.props.fields
        name.props.onChange(ev.target.value)
        break
      case 'birthName':
        const { birthName } = this.props.fields
        birthName.props.onChange(ev.target.value)
        break
      case 'birthPlace':
        const { birthPlace } = this.props.fields
        birthPlace.props.onChange(ev.target.value)
        break
      case 'motherName':
        const { motherName } = this.props.fields
        motherName.props.onChange(ev.target.value)
        break
      case 'idCardNumber':
        const { idCardNumber } = this.props.fields
        idCardNumber.props.onChange(ev.target.value)
        break
    }
  }

  updatePhone (name, fieldName, fieldValue) {
    if (name.startsWith('phone')) {
      let i = name.slice(-1)
      let tempPhones = this.state.customer.phones.slice()
      tempPhones[i] = { ...tempPhones[i], [fieldName]: fieldValue }
      this.setState({ customer: { ...this.state.customer, phones: tempPhones } })
      const { phones } = this.props.fields
      phones.props.onChange(tempPhones)
    } else {
      let newPhoneValue
      switch (name) {
        case 'newPhone':
          newPhoneValue = { ...this.state.newPhone, [fieldName]: fieldValue }
          this.setState({ newPhone : newPhoneValue })
          const { newPhone } = this.props.fields
          newPhone.props.onChange(newPhoneValue)
          break
        case 'ugyfelfiokPhone':
          newPhoneValue = { ...this.state.customer.ugyfelfiokPhone, [fieldName]: fieldValue }
          this.setState({ customer: { ...this.state.customer, ugyfelfiokPhone: newPhoneValue } })
          const { ugyfelfiokPhone } = this.props.fields
          ugyfelfiokPhone.props.onChange(newPhoneValue)
          break
      }
    }
  }

  updateAddress (newAddress) {
    this.setState({ customer: { ...this.state.customer, address: newAddress } })
    const { address } = this.props.fields
    address.props.onChange(newAddress)
  }

  updateNotificationAddress (newAddress) {
    this.setState({ customer: { ...this.state.customer, notificationAddress: newAddress } })
  }

  setSelectedValue (ev) {
    this.setState({ customer: { ...this.state.customer, [ev.name]: ev.value } })
  }

  onValidatedSelectedDataChange (ev) {
    this.setSelectedValue(ev)
    const { idCardType } = this.props.fields
    idCardType.props.onChange(ev)
  }

  onCheckboxChange (ev) {
    this.setState({ customer: { ...this.state.customer, [ev.name]: ev.checked } })
  }

  onCalendarChange (ev, name) {
    this.setState({ customer: { ...this.state.customer, [name]: ev.value } })
  }

  onValidatedCalendarChange (ev, name) {
    this.onCalendarChange(ev, name)
    const { birthDate } = this.props.fields
    birthDate.props.onChange(ev)
  }

  onAddPhoneClick (event) {
    event.preventDefault()
    if (this.state.newPhone.countryCode && this.state.newPhone.phoneNumber &&
      validatePhoneCountry(this.state.newPhone) && validatePhoneNumber(this.state.newPhone)) {
      this.setState({
        customer: {
          ...this.state.customer,
          phones: this.state.customer.phones.concat([this.state.newPhone])
        }
      })
      this.setState({ newPhone: { ...this.state.newPhone, countryCode: null, phoneNumber: '' } })
      this.setState({ newPhoneError: '' })
    } else {
      this.setState({ newPhoneError: 'Kérem adja meg a helyes telefonszámot!' })
    }
  }

  onDeletePhoneClick (event, index) {
    event.preventDefault()
    const newPhones = this.state.customer.phones.slice()
    newPhones.splice(index, 1)
    this.setState({ customer: { ...this.state.customer, phones: newPhones } })
  }

  onFilesChange (fileList) {
    this.setState({ files: fileList })
    const { files } = this.props.fields
    files.props.onChange(fileList)
  }

  onMoreDetailsClick (event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.state.moreDetails) {
      Popup.create({
        title: 'Figyelmeztetés',
        content: <div className='alert'>Ha a további ügyféladatokban módosítás történt, akkor ezek a visszalépés
          során törlésre kerülnek!<br />Biztos benne, hogy visszalép?</div>,
        buttons: {
          left: [{
            text: 'Igen',
            className: 'btn green',
            action: () => {
              this.setState({ moreDetails: !this.state.moreDetails })
              moreDetails = !moreDetails
              this.setValuesToValidation()
              const { customer } = this.props
              this.setState({ customer: { ...this.state.customer,
                nationality: customer.nationality,
                name: customer.name ? customer.name : '',
                birthName: customer.birthName ? customer.birthName : '',
                birthPlace: customer.birthPlace ? customer.birthPlace : '',
                birthDate: customer.birthDate ? new Date(customer.birthDate) : '',
                motherName: customer.motherName ? customer.motherName : '',
                idCardType: customer.idCardType,
                idCardNumber: customer.idCardNumber ? customer.idCardNumber : '',
                address: customer.address,
                taxId: customer.taxId ? customer.taxId : '',
                bankAccountNumber: customer.bankAccountNumber ? customer.bankAccountNumber : '' }
              })
              Popup.close()
            }
          }],
          right: [{
            text: 'Nem',
            className: 'btn white',
            action: () => {
              Popup.close()
            }
          }]
        }
      })
    } else {
      moreDetails = !moreDetails
      this.setState({ moreDetails: !this.state.moreDetails })
      this.setValuesToValidation()
    }
  }

  onSendClick (event) {
    event.preventDefault()
    event.stopPropagation()

    const { form } = this.props
    form.forceValidate()
    if (form.isValid()) {
      if (this.state.newPhone.phoneNumber && this.state.newPhone.phoneNumber.length > 0) {
        this.setState({ warningMessage: 'Új telefonszámot adott meg, de nem használta a Hozzáad gombot!' })
      } else {
        this.setState({ warningMessage: '' })
        const customerFormData = new FormData()

        if (this.state.moreDetails && this.state.files) {
          Array.from(this.state.files).map(file => customerFormData.append('files', file))
        }

        const additionalData = { detailsDataModified: this.state.moreDetails }

        customerFormData.append('customerWithAdditionalData',
          new Blob([JSON.stringify({ ...this.props.customer, ...this.state.customer, additionalData })], {
            type: 'application/json'
          }))

        this.props.saveData(customerFormData)
        window.scrollTo(0,0)
      }
    } else {
      this.setState({ warningMessage: 'Kérem ellenőrizze a megadott adatok helyességét!' })
    }
  }

  render () {
    const { ugyfelfiokPhone, email, phones, newPhone, name, birthName, birthPlace, birthDate, motherName, idCardType,
      idCardNumber, address, files } = this.props.fields
    const { Citizenship, IdCardType } = this.props.masterData

    const nationalityOptions = [{ label: 'Kérjük válasszon!', value: null }]
    if (Citizenship) {
      Object.entries(Citizenship).forEach(([key, value]) => { nationalityOptions.push({ label: value[0].content, value: value[0].content }) })
    }
    const idCardTypeOptions = [{ label: 'Kérjük válasszon!', value: null }]
    if (IdCardType) {
      Object.entries(IdCardType).forEach(([key, value]) => { idCardTypeOptions.push({ label: value[0].content, value: value[0].content }) })
    }

    const privateData = <div className='col'>
      <div className='form-row'>
        <div className='form-group col'>
          <label className='text-uppercase'>E-mail cím<span className='redStar'>*</span></label>
          <span className='icon-question-green'>
            <i className='fa fa-question-circle-o fa-1x' />
          </span>
          <InputText className='form-control'
            placeholder='E-mail cím'
            name='email'
            value={this.state.customer.email}
            onChange={this.onValidatedDataChange}
            helptext={iHelp(this.props.masterData, 'uf_ugyfelemail')}
            helptextclassname='help-message-right'
          />
          <div className={email.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
            <span className='warning-text'>{email.error}</span></div>
        </div>
      </div>
      <div className='form-row'>
        <div className='form-group col-7'>
          <label className='text-uppercase'>kapcsolatIPont telefonszám<span className='redStar'>*</span></label>
          <span className='icon-question-green'>
            <i className='fa fa-question-circle-o fa-1x' />
          </span>
          <Phone name='ugyfelfiokPhone'
            countryCode={this.state.customer.ugyfelfiokPhone.countryCode}
            phoneNumber={this.state.customer.ugyfelfiokPhone.phoneNumber}
            masterData={this.props.masterData}
            updatePhone={this.updatePhone}
            helpkey='uf_ugyfelfioktelefonszam'
            helptextclassname='help-message-right'
          />
          <div className={ugyfelfiokPhone.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
            <span className='warning-text'>{ugyfelfiokPhone.error}</span></div>
        </div>
      </div>
    </div>

    const contactBlock = <div className='box-body row'>
      {privateData}
      <div className='col-12'>
        <div className='form-row'>
          <div className='form-group col'>
            <label className='text-uppercase'>További telefonszámok</label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            {this.state.customer.phones ? this.state.customer.phones.map((phone, index) => {
              return <PhoneListEditor key={'phonediv' + (index)} index={index} customer={this.state.customer}
                masterData={this.props.masterData} updatePhone={this.updatePhone}
                onClick={(e) => this.onDeletePhoneClick(e, index)} />
            }) : null}
            <div className={phones.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
              <span className='warning-text'>{phones.error}</span></div>
            {this.state.customer.phones.length < 5
              ? <div className=''>
                <div className='phone form-row align-items-center'>
                  <div className='col-7'>
                    <Phone name='newPhone'
                      countryCode={this.state.newPhone.countryCode}
                      phoneNumber={this.state.newPhone.phoneNumber}
                      masterData={this.props.masterData}
                      updatePhone={this.updatePhone}
                      helpkey='uf_ugyfeltovabbitelszam'
                      helptextclassname='help-message-top'
                    />
                  </div>
                  <div className='col-2'>
                    <Button className='btn green' label='Hozzáad' onClick={this.onAddPhoneClick} />
                  </div>
                </div>
                <div className={newPhone.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
                  <span className='warning-text'>{newPhone.error}</span></div>
                <div className={this.state.newPhoneError ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
                  <span className='warning-text'>{this.state.newPhoneError}</span></div>
              </div>
              : null}
          </div>
        </div>
      </div>
      <div className='col-12'>
        <div className='form-group'>
          <label className='text-uppercase'>Tartózkodási hely/értesítési cím</label>
          <span className='icon-question-green'>
            <i className='fa fa-question-circle-o fa-1x' />
          </span>
          <Address address={this.props.customer.notificationAddress}
            masterData={this.props.masterData}
            updateAddress={this.updateNotificationAddress}
            helpkey='uf_ugyfelertesitesicim'
            helptextclassname='help-message-right'
          />
        </div>
      </div>
      <div className='col-12'>
        <div className='secondary-header'>Engedélyek és hozzáférések</div>
        <div className='form-row'>
          <div className='form-group col align-self-center'>
            <div className='d-flex'>
              <Checkbox label='<b>Bankcsoporti Közös Adatkezelés:</b> Személyre szabott ajánlatok kidolgozása és ajánlattétel (profilalkotás)'
                name='marketingEnabled'
                checked={this.state.customer.marketingEnabled}
                onChange={this.onCheckboxChange}
                helptext={iHelp(this.props.masterData, 'uf_ugyfelmarketinguzlet')}
                helptextclassname='help-message-top'
              />
              <span className='icon-question-green'>
                <i className='fa fa-question-circle-o fa-1x' />
              </span>
            </div>
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group col align-self-center'>
            <div className='d-flex'>
              <Checkbox label='<b>OTP Pénzügyi Pont Zrt.</b> részére történő adatátadás ajánlattétel céljából' name='financialEnabled'
                checked={this.state.customer.financialEnabled} onChange={this.onCheckboxChange}
                helptext={iHelp(this.props.masterData, 'uf_ugyfelmarketingpenzugy')}
                helptextclassname='help-message-top'
              />
              <span className='icon-question-green'>
                <i className='fa fa-question-circle-o fa-1x' />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    const detailsBlock = <div className='box-body row'>
      <div className='col-12'>
        <div className='secondary-header'>Személyes adatok</div>
        <div className='form-row'>
          <div className='form-group col-md-12 col-lg-6'>
            <label className='text-uppercase'>Név<span className='redStar'>*</span></label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <InputText className='form-control'
              placeholder='Név'
              name='name'
              value={this.state.customer.name}
              onChange={this.onValidatedDataChange}
              helptext={iHelp(this.props.masterData, 'uf_ugyfelnev')}
              helptextclassname='help-message-right'
            />
            <div className={name.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
              <span className='warning-text'>{name.error}</span></div>
          </div>
          <div className='form-group col-md-12 col-lg-6'>
            <label className='text-uppercase'>Születési név<span className='redStar'>*</span></label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <InputText className='form-control'
              placeholder='Születési név'
              name='birthName'
              value={this.state.customer.birthName}
              onChange={this.onValidatedDataChange}
              helptext={iHelp(this.props.masterData, 'uf_ugyfelszuletesinev')}
              helptextclassname='help-message-left'
            />
            <div className={birthName.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
              <span className='warning-text'>{birthName.error}</span></div>
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group col-md-12 col-lg-6'>
            <label className='text-uppercase'>Állampolgárság</label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <Dropdown options={nationalityOptions}
              name='nationality'
              readOnly
              value={this.state.customer.nationality}
              onChange={this.setSelectedValue}
              autoWidth={false}
              helptext={iHelp(this.props.masterData, 'uf_ugyfelallampolgarsag')}
              helptextclassname='help-message-right'
            />
          </div>
          <div className='form-group col-md-12 col-lg-6'>
            <label className='text-uppercase'>Anyja neve<span className='redStar'>*</span></label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <InputText className='form-control'
              placeholder='Anyja neve'
              name='motherName'
              value={this.state.customer.motherName}
              onChange={this.onValidatedDataChange}
              helptext={iHelp(this.props.masterData, 'uf_ugyfelanyjaneve')}
              helptextclassname='help-message-left'
            />
            <div className={motherName.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
              <span className='warning-text'>{motherName.error}</span></div>
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group col-md-12 col-lg-6'>
            <label className='text-uppercase'>Születési hely<span className='redStar'>*</span></label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <InputText className='form-control'
              placeholder='Születési hely'
              name='birthPlace'
              value={this.state.customer.birthPlace}
              onChange={this.onValidatedDataChange}
              helptext={iHelp(this.props.masterData, 'uf_ugyfelszuletesihely')}
              helptextclassname='help-message-right'
            />
            <div className={birthPlace.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
              <span className='warning-text'>{birthPlace.error}</span></div>
          </div>
          <div className='form-group col-md-12 col-lg-6'>
            <label className='text-uppercase'>Születési idő<span className='redStar'>*</span></label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <UFCalendar locale={hu} showIcon='true' dateFormat={dateFormat} placeholder='Kérjük válasszon'
              monthNavigator='true'
              yearNavigator='true'
              yearRange='1915:2030'
              onChange={(ev) => this.onValidatedCalendarChange(ev, 'birthDate')}
              value={this.state.customer.birthDate}
              helptext={iHelp(this.props.masterData, 'uf_ugyfelszuletesiido')}
              helptextclassname='help-message-left'
            />
            <div className={birthDate.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
              <span className='warning-text'>{birthDate.error}</span></div>
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group col-md-12 col-lg-6'>
            <label className='text-uppercase'>Azonosító okmány típusa<span className='redStar'>*</span></label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <Dropdown options={idCardTypeOptions}
              name='idCardType'
              readOnly
              value={this.state.customer.idCardType}
              onChange={this.onValidatedSelectedDataChange}
              autoWidth={false}
              helptext={iHelp(this.props.masterData, 'uf_azonokmanytipus')}
              helptextclassname='help-message-right'
            />
            <div className={idCardType.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
              <span className='warning-text'>{idCardType.error}</span></div>
          </div>
          <div className='form-group col-md-12 col-lg-6'>
            <label className='text-uppercase'>Azonosító okmány száma<span className='redStar'>*</span></label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <InputText className='form-control'
              placeholder='Azonosító okmány száma'
              name='idCardNumber'
              value={this.state.customer.idCardNumber}
              onChange={this.onValidatedDataChange}
              helptext={iHelp(this.props.masterData, 'uf_azonokmanyszam')}
              helptextclassname='help-message-left'
            />
            <div className={idCardNumber.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
              <span className='warning-text'>{idCardNumber.error}</span></div>
          </div>
        </div>
        <div className='secondary-header'>Címadatok</div>
        <div className='form-row'>
          <div className='form-group col'>
            <label className='text-uppercase'>Lakcím<span className='redStar'>*</span></label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <Address address={this.props.customer.address}
              masterData={this.props.masterData}
              updateAddress={this.updateAddress}
              helpkey='uf_ugyfellakcim'
              helptextclassname='help-message-right'
            />
            <div className={address.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
              <span className='warning-text'>{address.error}</span></div>
          </div>
        </div>
        <div className='secondary-header'>További adatok</div>
        <div className='form-row'>
          <div className='form-group col-md-12 col-lg-6'>
            <label className='text-uppercase'>Adóazonosító jel</label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <InputText className='form-control'
              placeholder='Adóazonosító jel'
              name='taxId'
              value={this.state.customer.taxId}
              onChange={this.setValue}
              helptext={iHelp(this.props.masterData, 'uf_ugyfeladoazonosito')}
              helptextclassname='help-message-right'
            />
          </div>
          <div className='form-group col-md-12 col-lg-6'>
            <label className='text-uppercase'>Bankszámlaszám</label>
            <span className='icon-question-green'>
              <i className='fa fa-question-circle-o fa-1x' />
            </span>
            <InputText className='form-control'
              placeholder='Bankszámlaszám'
              name='bankAccountNumber'
              value={this.state.customer.bankAccountNumber}
              onChange={this.setValue}
              helptext={iHelp(this.props.masterData, 'uf_ugyfelbankszamlaszam')}
              helptextclassname='help-message-left'
            />
          </div>
        </div>
        <div className='secondary-header'>Csatolt dokumentumok<span className='redStar'>*</span>
          <span className='icon-question-green'>
            <i className='fa fa-question-circle-o fa-1x' />
          </span>
        </div>
        <div className='row padding-btm20'>
          <div className=' col'>
            Több dokumentumot is kiválaszthat, de egy állomány legfeljebb 20MB, az összes állomány pedig legfeljebb 40MB lehet.
            <br />
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group col'>
            <FileUpload
              buttonLabel='Dokumentumok kiválasztása feltöltésre'
              onFilesChange={this.onFilesChange}
              helptext={iHelp(this.props.masterData, 'uf_csatoltdokumentumok')}
              helptextclassname='help-message-top'
            />
            <div className={files.error ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
              <span className='warning-text'>{files.error}</span></div>
            <InputText className='hidden-element' name='files' value={this.state.files} />
          </div>
        </div>
      </div>
    </div>

    return <div className='profile'>
      <form onSubmit={event => this.onSendClick(event)}>
        <div className='profile-box container-fluid'>
          <div className='primary-header row'>Kapcsolattartási adatok módosítása</div>
          { contactBlock }
          <div className='button rightButton form-row'>
            <div className='col'>
              <Button className='btn green' label={this.state.moreDetails ? 'További ügyféladatok elrejtése' : 'További ügyféladatok módosítása'}
                onClick={(event) => this.onMoreDetailsClick(event)} />
            </div>
          </div>
        </div>
        { this.state.moreDetails
          ? <div className='profile-box container-fluid'>
            <div className='primary-header row'>További ügyféladatok módosítása</div>
            { detailsBlock }
          </div>
          : null }
        <div className={this.state.warningMessage ? 'warning-message' : 'warning-message-hidden'}>{warningIcon}
          <span className='warning-text'>{this.state.warningMessage}</span></div>
        <div className='button'>
          <Button className='btn green' label='Módosítás' onClick={(event) => this.onSendClick(event)} />
          <Button className='btn white' label='Mégsem' onClick={(event) => this.props.onCancelClick(event)} />
        </div>
      </form>
    </div>
  }
}

IndividualProfile.propTypes = {
  customer: PropTypes.object,
  masterData: PropTypes.object,
  form: PropTypes.object,
  fields: PropTypes.array,
  saveData: PropTypes.func,
  onCancelClick: PropTypes.func
}

IndividualProfile = form(from(rulesMap))(IndividualProfile)
export default IndividualProfile

