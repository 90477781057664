import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { userIsAuthenticated } from '../store/api'
import { cleanLogin, doLogin } from './Login/modules/login'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

/*
 * Copyright 2020 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

export const UserIsAuthenticated = (DecoratedComponent) => {
	const mapDispatchToProps = (dispatch, ownProps) => ({
		dispatch: dispatch
	})

	const mapStateToProps = (state, ownProps) => ({
		login: state.login
	})

	class WrapperComponent extends Component {
		static propTypes = {
			login: PropTypes.object,
			dispatch: PropTypes.any
		}

		static displayName = DecoratedComponent.name + 'Authenticated'

		componentWillMount (...args) {
			const { dispatch } = this.props
			if (!userIsAuthenticated(null, dispatch)) {
				dispatch(cleanLogin())
				dispatch(push('/login'))
			}
			if (super.componentWillMount !== undefined) {
				super.componentWillMount(args)
			}
		}

		render () {
			return this.props.login && this.props.login.isAuthenticated
				? <DecoratedComponent {...this.props} reloadUserData={() => doLogin(this.props.dispatch)} />
				: <div>Felhasználó azonosítása...</div>
		}
	}

	return connect(mapStateToProps, mapDispatchToProps)(WrapperComponent)
}
