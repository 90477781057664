/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

const defaultProps = {
  title: null,
  className: null
}

class Header extends Component {
  render () {
    if (this.props.title) {
      return (
        <header className={this.props.className}>
          <h1 className={this.props.className + '__title'}>{this.props.title}</h1>
        </header>
      )
    }

    return null
  }
}

Header.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
}

Header.displayName = 'PopupHeader'
Header.defaultProps = defaultProps

export default Header
